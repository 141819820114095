import InteractionInsight from '@/lib/insights/models/InteractionInsight';

const endpointPrefix = (appObjectType) => {
  if (appObjectType === 'collection') {
    return 'v2/collections/';
  }
  if (appObjectType === 'group') {
    return 'v2/groups/';
  }
  return null;
};

const endpointFactory = (entityId, appObjectType) => {
  const endpointName = endpointPrefix(appObjectType);
  const url = `${endpointName}${entityId}/insights`;
  return url;
};

class AppObjectInsightsClient {
  /**
   *
   * @type {AxiosInstance}
   */
  httpClient = null;

  /**
   * 
   * @param httpClient{AxiosInstance}
   */
  constructor(httpClient) {
    this.httpClient = httpClient;
  }

  async getInsights(entityId, appObjectType) {
    const url = endpointFactory(entityId, appObjectType);
    const { data } = await this.httpClient.get(url);

    return InteractionInsight.fromObject(data);
  }

  async markAsViewed(entityId, appObjectType) {
    const partialUrl = endpointFactory(entityId, appObjectType);
    const url = `${partialUrl}/views`;

    try {
      await this.httpClient.post(url);
    } catch (e) {
      console.error(e);
    }

    return this.getInsights(entityId, appObjectType);
  }

  async getEntityViews(entities, appObjectType) {
    const entityIds = entities.map((entity) => entity.id);
    const urlPrefix = endpointPrefix(appObjectType);
    const url = `${urlPrefix}insights/views`;
    try {
      const { data } = await this.httpClient.post(url, entityIds);
      entities.map((entity) => entity.setViewsCount(data.find((e) => e.entity.externalId === entity.id)?.views.length || 0));
    } catch (e) {
      console.error(e);
    }
  }

  async like(entityId, appObjectType) {
    const partialUrl = endpointFactory(entityId, appObjectType);
    const url = `${partialUrl}/likes`;

    try {
      await this.httpClient.post(url);
    } catch (e) {
      console.error(e);
    }

    return this.getInsights(entityId, appObjectType);
  }
}

export default AppObjectInsightsClient;
