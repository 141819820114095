import StateNotifier from '@/lib/StateNotifier';
import DiscoveryNotifier from '@/lib/ui/notifier/DiscoveryNotifier';
import UserDeployments from '@/lib/deployments/models/UserDeployments';
import Deployment from '@/lib/deployments/models/Deployment';
import PanelManager from '@/lib/ui/panels/PanelManager';
import PermissionsChecker from '@/lib/acl/PermissionsChecker';
import SearchLogInput from '@/lib/search/discovery/models/SearchLogInput';
import AIDeployment from '@/openai/lib/models/ML/AIDeployment';

export default {
  activeUser: null,

  activeUserNotifier: new StateNotifier(),
  /**
   * To open a panel in right panel, set this to panel name
   */
  rightPanel: false,

  appBusy: false,
  consoleBusy: false,
  appBusyMessage: 'Busy Cursor!!',
  appBusyButtons: [],
  showBusyButtons: false,
  showSuccessfulIcon: false,
  showUnSuccessfulIcon: false,
  busyErrors: [],
  totalAssetErrors: 0,

  deployments: [],

  deployment: new Deployment(),
  deploymentIndex: null,

  /*
        this is not how it should be. but for now, this will do
   */
  showSharePanel: false,

  showRightPanel: false,
  rightPanelName: '',

  /* this flag is used to open/close the discovery dialog */
  showDiscoveryDialog: false,

  /* determines the current dialog to how */
  currentDialogType: null,

  /* this holds the object that the user has chosen to share */
  shareableObject: null,

  /* share message */
  shareMessage: {},

  deploymentIndexNotifier: new StateNotifier(null, 'index'),

  userDeploymentsNotifier: new StateNotifier(null, 'user deployments'),

  discoveryNotifier: new DiscoveryNotifier(),

  userDeployments: new UserDeployments(),

  indexColor: '',

  /* count of items to be fetched */
  fetchCount: 15,

  rightPanelManager: new PanelManager(),

  customizableEntity: null,

  pageGroups: [],

  indexPermissionCodes: [
    'editDeploymentIndex',
    'uploadAsset',
    'analyzeInsights',
    'exportReports',
    'viewOpenAi',
    'viewAiAssistant',
    'viewAiInteract',
    'seeHybridSearchButton',
    'viewRagAssistant',
  ],

  indexPermissions: new PermissionsChecker(),

  listRendererOptions: [
    { icon: 'view_compact', type: 'gallery', name: 'Gallery' },
    { icon: 'grid_on', type: 'card', name: 'Card' },
    // { icon: 'list', type: 'list', name: 'List' },
    { icon: 'grid_view', type: 'mobilecard', name: 'Mobile' },
  ],

  searchLog: new SearchLogInput(),

  isMySitesSelectorOpen: false,

  userDefaultIndex: null,
  isOpenAiAlertAcknowledged: false,
  ragEndpointInfo: null,
  pageBeforeNotFoundError: null,
  siteInfoForNotFound: null,
  systemMessage: null,
};
