import RequestAccessForm from '@/lib/requestAccess/models/RequestAccessForm';
import formFieldNames from '@/lib/requestAccess/models/formFieldNames';

const formUrl = 'https://app.smartsheet.com/b/form/8e8623f535454f4c8e8f125afee5a68f';
class SuggestATagForm extends RequestAccessForm {
  constructor() {
    super(formUrl);

    this.title = 'Suggest A Tag';
    this.type = 'Tag Suggestion';
  }

  set type(value) {
    this.formData[formFieldNames.TYPE] = value;
  }
}

export default SuggestATagForm;
