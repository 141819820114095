import { deploymentListFactory } from '@/lib/deployments/models/Deployment';

const UserDeployments = function UserDeployments(data) {
  /**
   * @type {[Deployment]}
   */
  this.deployments = (data && deploymentListFactory(data)) || [];

  /**
   *
   * @type {Deployment}
   */
  this.currentDeployment = null;
  // this.setCurrentDeployment(null);
};

/**
 *
 * @returns {DeploymentIndex[]}
 */
UserDeployments.prototype.allIndexes = function allIndexes() {
  const indices = (this.deployments || [])
    .flatMap((deployment) => deployment.deploymentIndexes || []);

  indices.sort((a, b) => {
    const aName = a.name.toLowerCase();
    const bName = b.name.toLowerCase();

    if (aName < bName) {
      return -1;
    }
    if (aName > bName) {
      return 1;
    }
    return 0;
  });

  return indices;
};

UserDeployments.prototype.indexWithId = function isIndexPresent(indexId) {
  const indexes = this.allIndexes();
  return indexes.find((index) => index.deploymentIndexId === indexId);
};

/**
 *
 * @param indexId
 * @returns {Deployment|null}
 */
UserDeployments.prototype.setUserSelectedDeployment = function setUserSelectedDeployment(indexId) {
  const index = this.indexWithId(indexId);
  if (index == null) {
    return null;
  }

  const currentDeployment = this.deployments.find(
    (deployment) => deployment.deploymentId === index.deploymentId,
  );

  if (currentDeployment == null) {
    return null;
  }

  this.setCurrentDeployment(currentDeployment);
  this.setCurrentIndex(index);

  return currentDeployment;
};

/**
 *
 * @param index{DeploymentIndex}
 */
UserDeployments.prototype.setCurrentIndex = function setCurrentIndex(index) {
  if (this.currentDeployment != null) {
    if (index.deploymentId !== this.currentDeployment.deploymentId) {
      this.currentDeployment.setCurrentIndex(null);
    } else {
      this.currentDeployment.setCurrentIndex(index);
    }
  }
};

/**
 *
 * @param deployment{Deployment}
 */
UserDeployments.prototype.setCurrentDeployment = function setCurrentDeployment(deployment) {
  this.unsetCurrentDeployment(deployment);

  this.currentDeployment = (
    deployment
    || this.deployments?.find((d) => d.isCurrent)
    || (this.deployments && this.deployments[0])
  );

  this.currentDeployment.isCurrent = true;
  return this.currentDeployment;
};

/**
 *
 * @param newDeployment{Deployment}
 */
UserDeployments.prototype.unsetCurrentDeployment = function unsetCurrentDeployment(newDeployment) {
  if (
    this.currentDeployment != null
    && newDeployment !== null
    && this.currentDeployment?.deploymentId !== newDeployment?.deploymentId
  ) {
    this.currentDeployment.unsetIsCurrent();
  }
};

export default UserDeployments;
