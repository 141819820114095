import * as Azure from '@azure/storage-blob';
import { RightPanels } from '@/lib/ui/panels/RightPanelFactory';
import router from '@/router';
// import Asset from '@/store/models/Asset';
import httpClient from './services';
// import { dispatch } from 'd3';

export default {
  async createNewMetadata({ commit }, assetWithMetadata) {
    // console.log('in actions createNewMetadata');
    // console.log(assetWithMetadata);
    const data = await httpClient.createNewMetadata(assetWithMetadata);
    // console.log(data);
    // commit('setAllData', data);
    // console.log(commit);
    return data;
  },
  async saveAssetWithMetadata({ commit }, assetWithMetadata) {
    // console.log('in actions saveAssetWithMetadata 11111');
    // console.log(assetWithMetadata);
    // console.log('in actions saveAssetWithMetadata 222222');
    const data = await httpClient.saveAssetWithMetadata(assetWithMetadata);
    // console.log(data);
    // commit('setAllData', data);
    // console.log(commit);
    return data;
  },
  async markAsUploaded({ commit }, assetInfo) {
    // console.log('in actions saveAssetWithMetadata');
    // console.log(assetInfo);
    const data = await httpClient.markAsUploaded(assetInfo);
    // console.log(data);
    // commit('setAllData', data);
    // console.log(commit);
    return data;
  },
  async markPreviewAsUploaded({ commit }, assetInfo) {
    // console.log('in actions saveAssetWithMetadata');
    // console.log(assetInfo);
    const data = await httpClient.markPreviewAsUploaded(assetInfo);
    // console.log(data);
    // commit('setAllData', data);
    // console.log(commit);
    return data;
  },
  async triggerVideoIndexing({ commit }, assetInfo) {
    // console.log('in actions TriggerVideoIndexing');
    // console.log(assetInfo);
    const data = await httpClient.triggerVideoIndexing(assetInfo);
    // console.log(data);
    // commit('setAllData', data);
    // console.log(commit);
    return data;
  },
  async updateAssetCollections({ commit, dispatch }, assetTransfer) {
    commit('common/setAppBusy', { busy: true, message: 'Updating assets and index...' }, { root: true });
    const data = await httpClient.updateAssetCollections(assetTransfer).then((assetXfer) => {
      if (assetXfer.hasError) {
        commit('common/addBusyError', assetXfer.error, { root: true });

        const finalMessage = 'An Error has occurred when Updating Metadata. Please try again.';

        commit('common/setAppBusy', { busy: true, message: finalMessage }, { root: true });
        commit('common/removeAppBusyButtons', {}, { root: true });
        commit('common/addAppBusyButton', { label: 'OK', description: 'Back to EditMetadata', action: 'uploader/busyCursorReset' }, { root: true });
        commit('common/setShowBusyButtons', true, { root: true });
      } else {
        commit('setSelectedSubsetId', assetXfer.subsetId);
        commit('setSelectedSetFieldValues', assetXfer.assetInfos[0].subset);
        commit('common/setAppBusy', { busy: false, message: '' }, { root: true });
      }
    }).catch((error) => {
      // console.log('in actions updateAssetCollections 11111111111aaaaaaaaaaaaaaaan 6');
      // console.log(error);

      // put into notifications here
      commit('common/addBusyError', error.toString, { root: true });

      const finalMessage = 'An Error has occurred when Updating Metadata. Please try again.';

      commit('common/setAppBusy', { busy: true, message: finalMessage }, { root: true });
      commit('common/removeAppBusyButtons', {}, { root: true });
      commit('common/addAppBusyButton', { label: 'OK', description: 'Back to EditMetadata', action: 'uploader/busyCursorReset' }, { root: true });
      commit('common/setShowBusyButtons', true, { root: true });
    });

    return data;
  },
  async getMetadataSets({ commit }) {
    const data = await httpClient.getEntities('getMetadataSets');
    commit('setMetadataSets', data);
    return data;
  },
  async getMetadataSetDataID({ commit, rootState }, setId) {
    const deploymentIndexId = rootState.common.deploymentIndex?.deploymentIndexId;
    const data = await httpClient.getEntities(`getUploaderSetById?setid=${setId}&deploymentIndexId=${deploymentIndexId}`);
    commit('setMetadataSetData', data);
    commit('resetSelections');
    commit('setUploaderPanelOpened', true);
    commit('common/setAppBusy', { busy: false }, { root: true });
    return data;
  },
  async getMetadataSetData({ commit, rootState }, set) {
    const deploymentIndexId = rootState.common.deploymentIndex?.deploymentIndexId;
    const setid = set.SetId;
    const data = await httpClient.getEntities(`getUploaderSetById?setid=${setid}&deploymentIndexId=${deploymentIndexId}`);
    commit('setMetadataSetData', data);
    commit('common/setAppBusy', { busy: false }, { root: true });
    return data;
  },

  /* Console Messages - make dynamic + enum later */

  // consoleRegisterMessage({ commit }) {
  //   commit('common/setConsoleBusy', { busy: true, message: 'Registering assets for uploading.' }, { root: true });
  // },
  consoleUploaderMessage({ commit }) {
    commit('common/setConsoleBusy', { busy: true, message: 'Uploading assets... 0%' }, { root: true });
  },
  previewConsoleUploaderMessage({ commit }) {
    commit('common/setConsoleBusy', { busy: true, message: 'Uploading previews... 0%' }, { root: true });
  },

  /* Console States */

  consoleFinishedUploading({
    commit, dispatch, rootGetters, getters,
  }, { successMessage, errorMessage, isEditMetadata }) {
    const hasBusyError = rootGetters['common/hasBusyError'];
    dispatch('common/setSuccessIcon', { otherErrorExists: getters.hasUploaderError }, { root: true });
    const hasError = getters.hasUploaderError;
    let finalMessage = '';
    if (hasError || hasBusyError) {
      finalMessage = errorMessage;
    } else {
      finalMessage = successMessage;
    }
    commit('common/setConsoleBusy', { busy: true, message: finalMessage }, { root: true });
    commit('common/removeAppBusyButtons', {}, { root: true });
    if (!isEditMetadata) {
      commit('common/addAppBusyButton', { label: 'ADD MORE', description: 'Continue uploading with same metadata', action: 'uploader/continueUploading' }, { root: true });
    }
    commit('common/addAppBusyButton', { label: 'CLOSE', description: 'Go to main page', action: 'uploader/consoleDoneUploading' }, { root: true });
    commit('common/setShowBusyButtons', true, { root: true });
  },
  consoleDoneUploading({ commit, dispatch }) {
    commit('uploader/resetAsset', {}, { root: true });
    commit('common/setConsoleBusy', { busy: false }, { root: true });
    commit('common/removeAppBusyButtons', {}, { root: true });
    dispatch('common/setRightPanel', null, { root: true });
    dispatch('common/setShowRightPanelState', false, { root: true });
    commit('resetUploader');
    commit('common/resetConsole', {}, { root: true });
  },

  /* Busy Cursor States */

  busyCursorReset({ commit }) {
    commit('common/busyCursorReset', {}, { root: true });
  },

  continueUploading({ commit, dispatch }) {
    // 2. hide busy cursor
    commit('uploader/resetAsset', {}, { root: true });
    commit('common/setConsoleBusy', { busy: false }, { root: true });
    commit('common/removeAppBusyButtons', {}, { root: true });
    commit('resetUploader');
    commit('common/busyCursorReset', {}, { root: true });
  },

  continueUploadingAndReset({ commit, dispatch }) {
    // 2. hide busy cursor
    // 3. reset data
    commit('common/setConsoleBusy', { busy: false }, { root: true });
    commit('common/removeAppBusyButtons', {}, { root: true });
    commit('resetUploader');
    commit('common/busyCursorReset', {}, { root: true });
  },

  /* Drop Files */

  addDropFiles({ commit }, e) {
    // console.log('assign filessssssss');
    commit('addUploadFiles', e.dataTransfer.files);
  },

  addDropPreviewFiles({ commit }, e) {
    // console.log('assign filessssssss');
    commit('addUploadPreviewFiles', e.dataTransfer.files);
  },

  /* -------------- */

  async setAssetByDocId({ commit, rootState }, docid) {
    commit('common/setAppBusy', { busy: true, message: 'Loading asset...' }, { root: true });

    commit('setSelectedDocId', docid);

    commit('setSelectedSetFieldValues', []);

    const data = await httpClient.getEntities(`getAssetForEditing?docid=${docid}`);

    commit('setSelectedSubsetId', data.subsetId);
    commit('setSelectedMasterSetId', data.masterSetId);

    commit('setMetadataSetData', data.assetInfos[0].masterset);
    commit('setSelectedSetFieldValues', data.assetInfos[0].subset);

    commit('resetSelections');

    commit('setEditMetatadataAssetInfo', data.assetInfos[0]);

    commit('common/setAppBusy', { busy: false, message: '' }, { root: true });

    return data[0];
  },
  async setEmptyAsset({ commit, rootState }) {
    commit('common/setAppBusy', { busy: true, message: 'Loading asset...' }, { root: true });

    commit('setSelectedDocId', null);

    commit('setSelectedSetFieldValues', []);

    const deploymentIndexxId = rootState.common.deploymentIndex?.deploymentIndexId;
    const data = await httpClient.getEntities(`getAssetWithDefaults?deploymentindexid=${deploymentIndexxId}`);

    commit('setSelectedSubsetId', data.subsetId);
    commit('setSelectedMasterSetId', data.masterSetId);

    commit('setMetadataSetData', data.assetInfos[0].masterset);
    commit('setSelectedSetFieldValues', data.assetInfos[0].subset);

    commit('resetSelections');

    commit('setEditMetatadataAssetInfo', data.assetInfos[0]);

    commit('common/setAppBusy', { busy: false, message: '' }, { root: true });

    return data[0];
  },
  async setAssetByDocIdCollections({ commit, state }, docid) {
    commit('common/setAppBusy', { busy: true, message: 'Loading asset...' }, { root: true });

    commit('setSelectedDocId', docid);

    commit('setSelectedSetFieldValues', []);

    const data = await httpClient.getEntities(`getAssetForCollections?docid=${docid}`);

    commit('setSelectedSubsetId', data.subsetId);
    commit('setSelectedMasterSetId', data.masterSetId);

    if (state.addToCollectionPanelOpened) {
      commit('setSavedSfvs', data.assetInfos[0].subset);
      const selectedSetFieldValues = data.assetInfos[0].subset;
      const { setFieldValues } = data.assetInfos[0].masterset;
      const selectedSet = new Set(selectedSetFieldValues.map((item) => item.fieldValue));
      const unselectedSetFieldValues = setFieldValues.filter((item) => !selectedSet.has(item.fieldValue));
      data.assetInfos[0].masterset.setFieldValues = unselectedSetFieldValues;
      commit('setMetadataSetData', data.assetInfos[0].masterset);
    } else {
      commit('setMetadataSetData', data.assetInfos[0].masterset);
      commit('setSelectedSetFieldValues', data.assetInfos[0].subset);
    }

    commit('resetSelections');

    commit('setEditMetatadataAssetInfo', data.assetInfos[0]);

    commit('common/setAppBusy', { busy: false, message: '' }, { root: true });

    return data[0];
  },

  /* -------------- */

  async openEditMetadata({ dispatch }, docId) {
    dispatch('uploader/setAssetByDocId', docId, { root: true });
    dispatch('common/openPanel', RightPanels.EDIT_METADATA, { root: true });
  },

  async openReplaceFilePanel({ commit, dispatch }) {
    dispatch('common/openPanel', RightPanels.REPLACE_FILE, { root: true });
  },

  async openFileDetailsPanel({ dispatch }) {
    dispatch('common/openPanel', RightPanels.FILE_DETAILS, { root: true });
  },

  async openAddToCollection({ dispatch, commit }, docId) {
    // console.log('Opening add collection 1');
    // const deploymentIndexId = rootState.common.deploymentIndex?.deploymentIndexId;
    // let selectedCollection; // rootState.discovery?.selectedCollection;
    // dispatch('getCollections', { deploymentIndexId, selectedCollection });
    dispatch('uploader/setAssetByDocIdCollections', docId, { root: true });
    dispatch('common/openPanel', RightPanels.ADD_TO_COLLECTIONS, { root: true });
    commit('setAddToCollectionPanelOpened', true);
  },

  /* -------------- */

  finishedAddingToCollection({ commit }) {
    const finalMessage = 'Your asset has been added to collection.';
    commit('common/setAppBusy', { busy: true, message: finalMessage }, { root: true });
    commit('common/addAppBusyButton', { label: 'Done', description: 'Go to main page', action: 'uploader/doneAddingToCollection' }, { root: true });
    commit('common/addAppBusyButton', {
      label: 'ADD MORE',
      description: 'Add asset to more collections',
      action: 'uploader/continueAddingToCollection',
      disabled: true,
    }, { root: true });
    commit('common/addAppBusyButton', {
      label: 'GO TO COLLECTIONS',
      description: 'Go to Collections',
      action: 'uploader/goToCollectionDetails',
      disabled: false,
    }, { root: true });
    commit('common/setShowBusyButtons', true, { root: true });
  },

  doneAddingToCollection({ commit, dispatch }) {
    dispatch('common/setShowRightPanelState', false, { root: true });
    dispatch('common/setRightPanel', null, { root: true });
    commit('uploader/resetAssetCollections', {}, { root: true });
    commit('common/setAppBusy', { busy: false }, { root: true });
    commit('common/removeAppBusyButtons', {}, { root: true });
  },

  continueAddingToCollection({ commit, dispatch }) {
    commit('common/setAppBusy', { busy: false }, { root: true });
    commit('common/removeAppBusyButtons', {}, { root: true });
    dispatch('common/setRightPanel', null, { root: false });
  },

  goToCollectionDetails({ commit, dispatch }) {
    commit('uploader/resetAssetCollections', {}, { root: true });
    commit('common/setAppBusy', { busy: false }, { root: true });
    commit('common/removeAppBusyButtons', {}, { root: true });
    dispatch('common/setRightPanel', null, { root: true });
    dispatch('common/setShowRightPanelState', false, { root: true });
    router.push({ path: '/collections' });
  },

  // opening, loading of uploader

  async openUploader({ dispatch }) {
    dispatch('loadUploaderData');
    dispatch('common/openPanel', RightPanels.UPLOADER, { root: true });
  },

  async openUploaderToCollection({ dispatch }, selectedCollection) {
    // console.log('heres the other add to collection location');
    dispatch('loadUploaderData', selectedCollection);
    dispatch('common/openPanel', RightPanels.UPLOADER, { root: true });
  },

  // async openUploaderAddCollection({ dispatch }) {
  //   // const deploymentIndexId = rootState.common.deploymentIndex?.deploymentIndexId;
  //   // dispatch('getCollections', { deploymentIndexId, selectedCollection });
  //   dispatch('common/openPanel', RightPanels.UPLOADER, { root: true });
  // },

  loadUploaderData({
    commit, dispatch, rootState,
  }) {
    commit('common/setAppBusy', { busy: true, message: 'Loading Uploader...' }, { root: true });
    const metadataSetId = rootState.common.deploymentIndex?.metadataSetId;
    dispatch('getMetadataSetDataID', metadataSetId);
  },

  setPrimaryAuthor({ commit, rootState }, useDefaultUser) {
    // console.log('look for primary author in Contributors');
    // console.log(rootState.uploader.primaryAuthor);
    // console.log(useDefaultUser);

    let primaryAuthor;
    commit('setPrimaryAuthor', primaryAuthor);
  },

  async createMetadataEdgeAction({ commit, dispatch }, edgeForm) {
    // console.log('in actions createMetadataEdge 11111111111aaaaaaaaaaaaaaaan 1');
    commit('common/setAppBusy', { busy: true, message: 'Adding Metadata...' }, { root: true });
    // console.log('in actions createMetadataEdge 11111111111aaaaaaaaaaaaaaaan 2');
    // const request = Asset.api().post('createMetadataEdgeAction', assetTransfer).then((assetXfer) => {
    const data = await httpClient.createMetadataEdge(edgeForm).then((response) => {
      // console.log('in actions createMetadataEdge 11111111111aaaaaaaaaaaaaaaan 3');
      // console.log(response);
      commit('common/setAppBusy', { busy: false, message: 'Finished' }, { root: true });
    }).catch((error) => {
      // console.log('in actions createMetadataEdge 11111111111aaaaaaaaaaaaaaaan 6');
      // console.log(error);
      commit('common/setAppBusy', { busy: false, message: 'Error...' }, { root: true });
    });

    return data;
  },

  /* Uploading and Saving */

  /* Upload Files */

  upload({ dispatch, commit, rootState }) {
    /*
      a. init console

      1. register
        2. transfer
          3. markasuploaded
            4. triggervideo
    */

    dispatch('initUploadOrUpdate', { message: 'Registering assets for uploading.', isPreview: false });

    // dispatch('prepareAssets');

    dispatch('registerAssetsInDb');
  },
  prepareAssets({ commit, dispatch, rootState }) {
    rootState.uploader.uploadFiles.forEach((file) => {
      const assetInfo = {
        BlobName: '',
        StoragePath: '',
        file: file,
        Status: 'none - unregistered',
        status_display: 'Registering...',
        UniqueId: Math.floor(Math.random() * 1000000000000).toString(),
        AssetId: '0',
        DocId: '',
        HasError: false,
        Error: '',
        FileName: file.name,
        OriginalFileName: file.name,
        filesize: file.size,
        loadedBytes: 0,
        loadedPercentage: 0,
        IsVideo: false,
        RegisterError: rootState.uploader.RegisterError,
        MarkAsUploadError: rootState.uploader.MarkAsUploadError,
        FakeErrorMessage: rootState.uploader.FakeErrorMessage,
        FoundPreviewAssetInfos: [],
        PreviewFound: false,
        MarkAsUploaded: false,
        MimeType: '',
        AlreadyUploaded: false,
        ShortLivedSas: 'notset',
      };
      commit('uploader/addAssetInfo', assetInfo, { root: true });
    });
  },
  registerAssetsInDb({ commit, dispatch, rootState }) {
    const assetData = {
      AssetInfos: rootState.uploader.assetInfos,
      DeploymentIndexId: rootState.common.deploymentIndex?.deploymentIndexId,
      MasterSetId: rootState.uploader.metadataSetData.setId,
      MetadataData: rootState.uploader.selectedSetFieldValues,
      BulkError: rootState.uploader.BulkError,
      FakeErrorMessage: rootState.uploader.FakeErrorMessage,
      PreviewAssetInfos: rootState.uploader.previewAssetInfos,
      UploadingAssets: true,
    };

    dispatch('saveAssetWithMetadata', assetData).then(async (serverAssetInfos) => {
      if (!serverAssetInfos) {
        return Promise.reject(new Error('Empty result, server error.'));
      }
      if (serverAssetInfos.HasError && serverAssetInfos.Error !== '') {
        commit('common/addBusyError', serverAssetInfos.Error, { root: true });
      }
      if (serverAssetInfos.AssetInfos.length < 1) {
        return Promise.reject(new Error('No asset info in request.'));
      }

      // rootState.uploader.assetInfos.forEach((ai) => {

      serverAssetInfos.AssetInfos.forEach((sai) => {
        // if (ai.UniqueId === sai.UniqueId) {
        // use mutations

        // ai.AssetId = sai.AssetId;
        // ai.FileName = sai.FileName;
        // ai.Status = sai.Status;
        // ai.DocId = sai.DocId;
        // ai.IsVideo = sai.IsVideo;
        // ai.Error += sai.Error;
        // ai.HasError = ai.HasError || sai.HasError;
        // ai.FoundPreviewAssetInfos = sai.FoundPreviewAssetInfos;

        commit('updateAssetInfo', sai);

        if (sai.FoundPreviewAssetInfos) {
          // rootState.uploader.previewAssetInfos.forEach((pai) => {
          // assetData.PreviewAssetInfos.forEach((pai) => {
          sai.FoundPreviewAssetInfos.forEach((fpai) => {
            // if (fpai.UniqueId === pai.UniqueId) {
            //   fpai.file = pai.file;
            //   fpai.filesize = pai.filesize;
            //   fpai.status_display = pai.status_display;
            // }
            commit('updatePreviewAssetInfo', fpai);
          });
          // });
        }

        if (sai.HasError) {
          rootState.uploader.numberUploadInProgresss -= 1;
          rootState.uploader.numberNotMarkedUploadedInDb -= 1;
          commit('common/addAssetError', sai, { root: true });
          dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Upload complete!</h4>Files uploaded successfully will be processed, indexed and made available on the discovery portal. Processing times can vary based on file types and sizes.', errorMessage: 'There are some errors processing your files, please see details above. Files uploaded successfully will be processed, indexed and made available on the Discovery portal. Processing times can vary based on file types and sizes.', isEditMetadata: false });
        }
        // }
      });

      // });

      await dispatch('transferAllFiles', false);

      return serverAssetInfos;
    }).catch((error) => {
      // console.log('IIOIOIOIOIOIOIOIOIOIOIOIOIOIOIOIOIOIOIOIOIO AAAAA 123');
      commit('common/addBusyError', error.toString(), { root: true });
      rootState.uploader.numberUploadInProgresss = 0;
      rootState.uploader.numberNotMarkedUploadedInDb = 0;
      dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Upload complete!</h4>Files uploaded successfully will be processed, indexed and made available on the discovery portal. Processing times can vary based on file types and sizes.', errorMessage: 'There are some errors processing your files, please see details above. Files uploaded successfully will be processed, indexed and made available on the Discovery portal. Processing times can vary based on file types and sizes.', isEditMetadata: true });
    });
  },
  localTriggerVideoIndexing({ commit, dispatch, rootState }, assetInfo) {
    // for debug
    assetInfo.InjectError = rootState.uploader.InjectError;
    dispatch('triggerVideoIndexing', assetInfo).then((serverAssetInfo) => {
      assetInfo.Status = serverAssetInfo.Status;
      assetInfo.Error += serverAssetInfo.Error;
      assetInfo.HasError = assetInfo.HasError || serverAssetInfo.HasError;
    }).catch((error) => {
      // console.log(error);
      assetInfo.Status = 'error';
      assetInfo.Error += error.toString();
      assetInfo.HasError = true;
    });
  },
  checkRegisterUploadMarkingStatus({ commit, dispatch, rootState }, { successMessage, errorMessage, isEditMetadata }) {
    if (rootState.uploader.numberUploadInProgresss <= 0 && rootState.uploader.numberNotMarkedUploadedInDb <= 0) {
      dispatch('consoleFinishedUploading', { successMessage: successMessage, errorMessage: errorMessage, isEditMetadata: isEditMetadata });
    }
  },

  /* init upload or update */

  initUploadOrUpdate({ commit, dispatch, rootState }, data) {
    const { message } = data;
    const { isPreview } = data;

    commit('common/resetConsole', {}, { root: true });
    commit('uploader/clearAssetInfos', null, { root: true });
    commit('uploader/clearPreviewAssetInfos', null, { root: true });
    commit('common/setConsoleBusy', { busy: true, message: message }, { root: true });
    dispatch('preparePreviewsForUpload', message);

    if (!isPreview) {
      dispatch('prepareAssets');
    } else {
      dispatch('prepareAssetForUpdate');
    }

    // asset files
    rootState.uploader.numberUploads = rootState.uploader.assetInfos.length;
    rootState.uploader.numberUploadInProgresss = rootState.uploader.numberUploads;
    rootState.uploader.numberNotMarkedUploadedInDb = rootState.uploader.numberUploads;
    // previews
    rootState.uploader.numberPreviewUploads = rootState.uploader.previewAssetInfos.length;
    rootState.uploader.numberPreviewUploadInProgresss = rootState.uploader.numberPreviewUploads;
  },

  /* Progress UI */

  updateProgress({ commit, rootState, dispatch }, data) {
    // console.log('updateProgress 1');
    const { isPreview } = data;
    if (!isPreview) {
      commit('updateProgress', data);
      const fileInfo = rootState.uploader.assetInfos.find((ai) => ai.OriginalFileName === data.filename);
      if (fileInfo.loadedPercentage >= 100) {
        dispatch('localCheckMainFileToMarkAsUploaded', fileInfo);
      }
    } else {
      commit('updatePreviewProgress', data);
    }
    commit('updateProgressMessage');
    commit('common/setConsoleBusy', { busy: true, message: `Uploading assets... ${rootState.uploader.totalProgress}%.` }, { root: true });
  },

  /* Transferring of Files */

  async transferAllFiles({ commit, dispatch, rootState }, isPreview) {
    rootState.uploader.newNumberUploadInProgresss = 0;

    if (isPreview) {
      dispatch('previewConsoleUploaderMessage');
    } else {
      dispatch('consoleUploaderMessage');
    }
    if (rootState.uploader.assetInfos) {
      rootState.uploader.newNumberUploadInProgresss += rootState.uploader.assetInfos.length;
      await Promise.all(rootState.uploader.assetInfos.map(async (assetInfo) => {
        if (assetInfo.HasError === false && assetInfo.Error === '') {
          if (!assetInfo.AlreadyUploaded) {
            await dispatch('transferFile', { assetInfo: assetInfo, isPreview: false });
          } else {
            rootState.uploader.newNumberUploadInProgresss -= 1;
            rootState.uploader.numberUploadInProgresss -= 1;
          }
        }
      }));
    }

    if (rootState.uploader.previewAssetInfos) {
      rootState.uploader.newNumberUploadInProgresss += rootState.uploader.previewAssetInfos.length;
      await Promise.all(rootState.uploader.previewAssetInfos.map(async (previewAssetInfo) => {
        if (previewAssetInfo.HasError === false && previewAssetInfo.Error === '') {
          await dispatch('transferFile', { assetInfo: previewAssetInfo, isPreview: true });
        } else {
          rootState.uploader.newNumberUploadInProgresss -= 1;
        }
      }));
    }
  },
  async transferFile({ commit, dispatch, rootState }, data) {
    // const customBlockSize = file.size > 1024 * 1024 * 32 ? 1024 * 1024 * 4 : 1024 * 512;
    // this.blobService.singleBlobPutThresholdInBytes = customBlockSize;
    // console.log('transferFile 1');

    const { assetInfo } = data;
    const { isPreview } = data;

    const { file } = assetInfo;
    const newFileName = assetInfo.FileName;
    const { deploymentIndex } = rootState.common;

    console.log('in transferrrrrrrrrrrrrrr 888888888888888888');
    // console.log();

    rootState.uploader.sasConnectionString = `BlobEndpoint=${deploymentIndex.containerUrl};SharedAccessSignature=${assetInfo.ShortLivedSas}`;

    let { containerName } = deploymentIndex;
    let fullurl = '';
    if (isPreview) {
      containerName = deploymentIndex.previewContainerName;

      let blobSas = '';
      const sasInput = {
        deploymentIndexId: deploymentIndex.id,
        containerName: containerName,
        blobName: newFileName,
      };
      blobSas = await dispatch('common/getSasToken', sasInput, { root: true });
      fullurl = `${deploymentIndex.containerUrl}${deploymentIndex.previewContainerName}/${newFileName}${blobSas}`;
    } else {
      fullurl = `${deploymentIndex.containerUrl}${deploymentIndex.containerName}/${newFileName}${assetInfo.ShortLivedSas}`;
    }
    const blobServiceClient = new Azure.BlockBlobClient(
      fullurl,
    );
    assetInfo.loadedBytes = 0;
    assetInfo.status_display = 'Uploading...';

    let contentmimetype = file.type;
    if (assetInfo.MimeType) {
      contentmimetype = assetInfo.MimeType;
    }

    const blobOptions = {
      blobHTTPHeaders: { blobContentType: contentmimetype },
      onProgress: (event) => dispatch('updateProgress', { event: event, filename: file.name, isPreview: isPreview }),
      metadata: { doc_id: assetInfo.DocId },
      // const options = { retryOptions: { maxRetryDelayInMs: 200, maxTries: 4, retryPolicyType: StorageRetryPolicyType.FIXED } };
    };

    try {
      const response = await blobServiceClient.uploadData(file, blobOptions);
      console.log('uploadData successssssssssssssssssssss 1');
      rootState.uploader.newNumberUploadInProgresss -= 1;
      if (isPreview) {
        const index = rootState.uploader.uploadPreviewFiles.indexOf(file);
        if (index > -1) {
          rootState.uploader.uploadPreviewFiles.splice(index, 1);
        }
      } else {
        rootState.uploader.numberUploadInProgresss -= 1;
        const index = rootState.uploader.uploadFiles.indexOf(file);
        if (index > -1) {
          rootState.uploader.uploadFiles.splice(index, 1);
        }
      }

      let storagePath = blobServiceClient.url;
      if (blobServiceClient.url.indexOf('?') >= 0) {
        storagePath = blobServiceClient.url.substring(0, blobServiceClient.url.indexOf('?'));
      }
      assetInfo.BlobName = blobServiceClient.name;
      assetInfo.StoragePath = storagePath;
      assetInfo.status_display = 'Metadata processing...';

      console.log('uploadData successssssssssssssssssssss 2');
      if (isPreview) {
        console.log('uploadData successssssssssssssssssssss 3');
        commit('updatePreviewAssetInfo', assetInfo);
        dispatch('localCheckPreviewFileToMarkAsUploaded', assetInfo);
      } else {
        commit('updateAssetInfo', assetInfo);
        dispatch('localCheckMainFileToMarkAsUploaded', assetInfo);
      }
    } catch (error) {
      console.log('uploadData failllllllllllleeeeeeeeeeeeeeed 1');
      assetInfo.HasError = true;
      assetInfo.Error += ` Transfer error: ${error.toString()}`;

      rootState.uploader.newNumberUploadInProgresss -= 1;
      if (isPreview) {
        commit('updatePreviewAssetInfo', assetInfo);
        dispatch('localCheckPreviewFileToMarkAsUploaded', assetInfo);
      } else {
        rootState.uploader.numberUploadInProgresss -= 1;
        commit('updateAssetInfo', assetInfo);
        dispatch('localCheckMainFileToMarkAsUploaded', assetInfo);
      }

      commit('common/addAssetError', assetInfo, { root: true });
    }
  },

  /* Preview Files */

  preparePreviewsForUpload({ commit, dispatch, rootState }) {
    commit('uploader/clearPreviewAssetInfos', null, { root: true });
    rootState.uploader.uploadPreviewFiles.forEach((file) => {
      rootState.uploader.previewAssetInfos.push({
        BlobName: '',
        StoragePath: '',
        file: file,
        Status: 'none - unregistered',
        status_display: 'Registering...',
        UniqueId: Math.floor(Math.random() * 1000000000000).toString(),
        ParentUniqueId: -1,
        AssetId: '0',
        DocId: '',
        HasError: false,
        Error: '',
        FileName: file.name,
        OriginalFileName: file.name,
        filesize: file.size,
        loadedBytes: 0,
        loadedPercentage: 0,
        IsVideo: false,
        RegisterError: rootState.uploader.RegisterError,
        MarkAsUploadError: rootState.uploader.MarkAsUploadError,
        FakeErrorMessage: rootState.uploader.FakeErrorMessage,
        FoundPreviewAssetInfos: [], // placeholder, not used
        PreviewFound: false,
        MimeType: '',
        AlreadyUploaded: false,
        ShortLivedSas: 'notset',
      });
    });
  },

  /* Edit Metadata */

  update({ dispatch, commit, rootState }) {
    dispatch('initUploadOrUpdate', { message: 'Updating metadata and previews...', isPreview: true });
    // dispatch('prepareAssetForUpdate');
    dispatch('updateAssetWithMetadata');
  },
  prepareAssetForUpdate({ commit, dispatch, rootState }) {
    const assetInfo = {
      BlobName: '',
      StoragePath: '',
      file: null,
      Status: 'Uploaded',
      status_display: 'Updating',
      UniqueId: Math.floor(Math.random() * 1000000000000).toString(),
      AssetId: 0,
      DocId: rootState.uploader.selectedDocId,
      HasError: false,
      Error: '',
      FileName: rootState.uploader.editMetatadataAssetInfo.fileName,
      OriginalFileName: '',
      filesize: 0,
      loadedBytes: 0,
      loadedPercentage: 0,
      IsVideo: false,
      RegisterError: rootState.uploader.RegisterError,
      MarkAsUploadError: rootState.uploader.MarkAsUploadError,
      FakeErrorMessage: rootState.uploader.FakeErrorMessage,
      FoundPreviewAssetInfos: [],
      PreviewFound: false,
      MarkAsUploaded: false,
      MimeType: '',
      AlreadyUploaded: true,
      ShortLivedSas: 'notset',
    };

    commit('uploader/addAssetInfo', assetInfo, { root: true });
  },
  async updateAssetWithMetadata({ commit, dispatch, rootState }) {
    const assetData = {
      AssetInfos: rootState.uploader.assetInfos,
      DeploymentIndexId: rootState.common.deploymentIndex?.deploymentIndexId,
      SingleDocId: rootState.uploader.selectedDocId,
      MasterSetId: rootState.uploader.metadataSetData.setId,
      SubsetId: rootState.uploader.selectedSubsetId,
      MetadataData: rootState.uploader.selectedSetFieldValues,
      RegisterError: rootState.uploader.RegisterError,
      InjectError: rootState.uploader.InjectError,
      AssociationError: rootState.uploader.AssociationError,
      FakeErrorMessage: rootState.uploader.FakeErrorMessage,
      PreviewAssetInfos: rootState.uploader.previewAssetInfos,
      UploadingAssets: false,
    };

    const data = await httpClient.updateAssetWithMetadata(assetData).then(async (serverAssetInfos) => {
      console.log('updateAssetWithMetadata success 1');
      if (!serverAssetInfos) {
        return Promise.reject(new Error('Empty result, server error.'));
      }
      if (serverAssetInfos.HasError && serverAssetInfos.Error !== '') {
        commit('common/addBusyError', serverAssetInfos.Error, { root: true });
      }
      if (serverAssetInfos.AssetInfos.length < 1) {
        return Promise.reject(new Error('No asset info in request.'));
      }
      console.log('updateAssetWithMetadata success 2');

      rootState.uploader.assetInfos.forEach((ai) => {
        serverAssetInfos.AssetInfos.forEach((sai) => {
          if (ai.UniqueId === sai.UniqueId) {
            ai.Error += sai.Error;
            ai.HasError = sai.HasError;
            ai.FoundPreviewAssetInfos = sai.FoundPreviewAssetInfos;
            ai.ParentUniqueId = sai.ParentUniqueId;

            if (sai.HasError) {
              rootState.uploader.numberUploadInProgresss -= 1;
              rootState.uploader.numberNotMarkedUploadedInDb -= 1;
              commit('common/addAssetError', ai, { root: true });
              dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Update success.</h4>', errorMessage: 'There are some errors updating metadatga, please see details above.', isEditMetadata: true });
            }
          }
        });
      });

      console.log('updateAssetWithMetadata success 3');

      if (rootState.uploader.previewAssetInfos && rootState.uploader.previewAssetInfos.length > 0) {
        await dispatch('transferAllFiles', false);
      } else {
        console.log('updateAssetWithMetadata success 3333333b');
        rootState.uploader.numberUploadInProgresss = 0;
        rootState.uploader.numberNotMarkedUploadedInDb = 0;
        dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Update complete!', errorMessage: 'There are some errors updating meatadata.', isEditMetadata: true });
      }

      console.log('updateAssetWithMetadata success 4');

      return serverAssetInfos;
    }).catch((error) => {
      console.log('updateAssetWithMetadata error 1');
      console.log(JSON.stringify(error));
      commit('common/addBusyError', error.toString(), { root: true });
      rootState.uploader.numberUploadInProgresss = 0;
      rootState.uploader.numberNotMarkedUploadedInDb = 0;
      dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Update complete!</h4> Metadata has been saved and indexed.', errorMessage: 'There are some errors updating the metadata, please see details above.', isEditMetadata: true });
    });
    return data;
  },

  /* AddToCollection */

  updateCollections({ dispatch, commit, rootState }) {
    dispatch('initUploadOrUpdate', { message: 'Updating collections...', isPreview: true });
    // dispatch('prepareAssetForUpdate');
    dispatch('updateAssetWithMetadataAddCollectionProfile');
  },

  async updateAssetWithMetadataAddCollectionProfile({ commit, dispatch, rootState }) {
    if (rootState.uploader.addToCollectionPanelOpened) {
      rootState.uploader.selectedSetFieldValues = [
        ...rootState.uploader.selectedSetFieldValues,
        ...rootState.uploader.savedSfvs,
      ].filter(
        (value, index, self) => index === self.findIndex((item) => item.profileFieldValueId === value.profileFieldValueId),
      );
    }
    const assetData = {
      AssetInfos: rootState.uploader.assetInfos,
      DeploymentIndexId: rootState.common.deploymentIndex?.deploymentIndexId,
      SingleDocId: rootState.uploader.selectedDocId,
      MasterSetId: rootState.uploader.metadataSetData.setId,
      SubsetId: rootState.uploader.selectedSubsetId,
      MetadataData: rootState.uploader.selectedSetFieldValues,
      RegisterError: rootState.uploader.RegisterError,
      InjectError: rootState.uploader.InjectError,
      AssociationError: rootState.uploader.AssociationError,
      FakeErrorMessage: rootState.uploader.FakeErrorMessage,
      PreviewAssetInfos: rootState.uploader.previewAssetInfos,
      UploadingAssets: false,
    };

    const data = await httpClient.updateAssetCollections(assetData).then(async (serverAssetInfos) => {
      console.log('updateAssetWithMetadata success 1');
      if (!serverAssetInfos) {
        return Promise.reject(new Error('Empty result, server error.'));
      }
      if (serverAssetInfos.HasError && serverAssetInfos.Error !== '') {
        commit('common/addBusyError', serverAssetInfos.Error, { root: true });
      }
      if (serverAssetInfos.AssetInfos.length < 1) {
        return Promise.reject(new Error('No asset info in request.'));
      }
      console.log('updateAssetWithMetadata success 2');

      rootState.uploader.assetInfos.forEach((ai) => {
        serverAssetInfos.AssetInfos.forEach((sai) => {
          if (ai.UniqueId === sai.UniqueId) {
            ai.Error += sai.Error;
            ai.HasError = sai.HasError;
            ai.FoundPreviewAssetInfos = sai.FoundPreviewAssetInfos;
            ai.ParentUniqueId = sai.ParentUniqueId;

            if (sai.HasError) {
              rootState.uploader.numberUploadInProgresss -= 1;
              rootState.uploader.numberNotMarkedUploadedInDb -= 1;
              commit('common/addAssetError', ai, { root: true });
              dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Update success.</h4>', errorMessage: 'There are some errors updating metadatga, please see details above.', isEditMetadata: true });
            }
          }
        });
      });

      console.log('updateAssetWithMetadata success 3');

      if (rootState.uploader.previewAssetInfos && rootState.uploader.previewAssetInfos.length > 0) {
        await dispatch('transferAllFiles', false);
      } else {
        console.log('updateAssetWithMetadata success 3333333b');
        rootState.uploader.numberUploadInProgresss = 0;
        rootState.uploader.numberNotMarkedUploadedInDb = 0;
        dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Update complete!', errorMessage: 'There are some errors updating meatadata.', isEditMetadata: true });
      }

      console.log('updateAssetWithMetadata success 4');

      return serverAssetInfos;
    }).catch((error) => {
      console.log('updateAssetWithMetadata error 1');
      console.log(JSON.stringify(error));
      commit('common/addBusyError', error.toString(), { root: true });
      rootState.uploader.numberUploadInProgresss = 0;
      rootState.uploader.numberNotMarkedUploadedInDb = 0;
      dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Update complete!</h4> Metadata has been saved and indexed.', errorMessage: 'There are some errors updating the metadata, please see details above.', isEditMetadata: true });
    });
    return data;
  },

  /* check states */

  localCheckPreviewFileToMarkAsUploaded({ commit, dispatch, rootState }, previewAssetInfo) {
    const masterAssetInfo = rootState.uploader.previewAssetInfos.find((pai) => pai.UniqueId === previewAssetInfo.UniqueId);

    console.log(`localCheckPreviewFileToMarkAsUploaded 1: ${previewAssetInfo.OriginalFileName}`);
    previewAssetInfo.status_display = 'Assigning as preview...';
    const assetInfo = rootState.uploader.assetInfos.find((ai) => ai.UniqueId === masterAssetInfo.ParentUniqueId);

    let foundFileInfo;
    rootState.uploader.assetInfos.forEach((ai) => {
      ai.FoundPreviewAssetInfos.forEach((fpai) => {
        if (fpai.OriginalFileName === masterAssetInfo.OriginalFileName) {
        // if (fpai.ParentUniqueId === ai.UniqueId && fpai.OriginalFileName === filename) {
          foundFileInfo = ai;
        }
      });
    });

    console.log('localCheckPreviewFileToMarkAsUploaded 2');
    console.log(JSON.stringify(foundFileInfo));
    if (foundFileInfo.AlreadyUploaded || foundFileInfo.loadedPercentage >= 100) {
      console.log(`localCheckPreviewFileToMarkAsUploaded passed: ${previewAssetInfo.OriginalFileName}`);
      dispatch('localCheckMainFileToMarkAsUploaded', foundFileInfo);
    }
  },
  localCheckMainFileToMarkAsUploaded({ rootState, commit, dispatch }, mainAssetInfo) {
    const assetInfo = rootState.uploader.assetInfos.find((pai) => pai.UniqueId === mainAssetInfo.UniqueId);
    console.log(JSON.stringify(assetInfo));
    console.log(' ');
    console.log(' ');
    console.log(' ');
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log((JSON.stringify(assetInfo)));
    console.log(`Checking Main file: ${assetInfo.FileName}`);
    console.log(`Main LoadedPercentage file: ${assetInfo.loadedPercentage}`);
    console.log('-');
    let allUploaded = false;
    if (assetInfo.AlreadyUploaded || (assetInfo.StoragePath && assetInfo.loadedPercentage === 100)) {
      console.log('localCheckMainFileToMarkAsUploaded 2');
      allUploaded = true;
    }
    if (allUploaded === true) {
      console.log('localCheckMainFileToMarkAsUploaded 3');
      if (assetInfo.FoundPreviewAssetInfos) {
        console.log('localCheckMainFileToMarkAsUploaded 4');
        assetInfo.FoundPreviewAssetInfos.forEach((fpai) => {
          console.log('localCheckMainFileToMarkAsUploaded 5');
          console.log(`Checking if uploaded: ${fpai.FileName}`);
          console.log(`Preview Loaded Percentage: ${fpai.loadedPercentage}`);
          console.log(`StoragePath: ${fpai.StoragePath}`);
          console.log('-');
          let previewUploaded = false;
          console.log('localCheckMainFileToMarkAsUploaded 5b');
          console.log(fpai.StoragePath);
          console.log(fpai.loadedPercentage);
          if (fpai.StoragePath && fpai.loadedPercentage === 100) { // !fpai.StoragePath ||
            console.log('localCheckMainFileToMarkAsUploaded 6');
            previewUploaded = true;
          }
          console.log('localCheckMainFileToMarkAsUploaded 7');
          if (previewUploaded === false) {
            console.log('localCheckMainFileToMarkAsUploaded 8');
            allUploaded = false;
          }
        });
      }
      console.log('localCheckMainFileToMarkAsUploaded 9');
      console.log(allUploaded);
      console.log(assetInfo.MarkAsUploaded);
      if (allUploaded === true && assetInfo.MarkAsUploaded === false) {
        console.log('localCheckMainFileToMarkAsUploaded 10');
        console.log(`Calling mark uploaded from: ${assetInfo.FileName}`);
        if (assetInfo.AlreadyUploaded) {
          dispatch('localMarkPreviewAsUploaded', assetInfo);
        } else {
          dispatch('localMarkAsUploaded', assetInfo);
        }
      }
    }
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log('zzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzzz');
    console.log(' ');
    console.log(' ');
    console.log(' ');
  },
  localMarkAsUploaded({ commit, dispatch, rootState }, assetInfo) {
    console.log(' ');
    console.log(' ');
    console.log(' ');
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log('ssssssssssssssssssssssssssssssssssssssssssssssssss');
    console.log(JSON.stringify(assetInfo));
    console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log(' ');
    console.log(' ');
    console.log(' ');

    assetInfo.MarkAsUploaded = true;
    // for debug
    assetInfo.InjectError = rootState.uploader.InjectError;
    dispatch('markAsUploaded', assetInfo).then((serverAssetInfo) => {
      // console.log('in markAsUploaded 1111112222222333333 444444444');
      // console.log(assetInfo);
      // console.log(serverAssetInfo);
      console.log('backkckckckckckckckckckckkckckckckckckckckckckkckckckcckckkckckc 1');
      console.log(JSON.stringify(assetInfo));
      console.log('backkckckckckckckckckckckkckckckckckckckckckckkckckckcckckkckckc 2');

      assetInfo.status_display = 'Indexing...';
      assetInfo.Status = serverAssetInfo.Status;

      if (assetInfo.FoundPreviewAssetInfos) {
        assetInfo.FoundPreviewAssetInfos.forEach((fpai) => {
          fpai.status_display = 'Assigned';
          fpai.Status = serverAssetInfo.Status;
        });
      }

      if (serverAssetInfo.HasError) {
        commit('common/addAssetError', assetInfo, { root: true });
        assetInfo.Error += serverAssetInfo.Error;
        assetInfo.HasError = true;
      }
      rootState.uploader.numberNotMarkedUploadedInDb -= 1;
      dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Upload complete!</h4>Files uploaded successfully will be processed, indexed and made available on the discovery portal. Processing times can vary based on file types and sizes.', errorMessage: 'There are some errors processing your files, please see details above. Files uploaded successfully will be processed, indexed and made available on the Discovery portal. Processing times can vary based on file types and sizes.', isEditMetadata: false });
      if (assetInfo.IsVideo) {
        dispatch('localTriggerVideoIndexing', assetInfo);
      }
    }).catch((error) => {
      // console.log(error);
      commit('common/addAssetError', assetInfo, { root: true });
      assetInfo.Status = 'error';
      assetInfo.Error += error.toString();
      assetInfo.HasError = true;
      rootState.uploader.numberNotMarkedUploadedInDb -= 1;
      dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Upload complete!</h4>Files uploaded successfully will be processed, indexed and made available on the discovery portal. Processing times can vary based on file types and sizes.', errorMessage: 'There are some errors processing your files, please see details above. Files uploaded successfully will be processed, indexed and made available on the Discovery portal. Processing times can vary based on file types and sizes.', isEditMetadata: false });
    });
  },
  localMarkPreviewAsUploaded({ commit, dispatch, rootState }, assetInfo) {
    console.log(' ');
    console.log(' ');
    console.log(' ');
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log('ssssssssssssssssssssssssssssssssssssssssssssssssss');
    console.log(JSON.stringify(assetInfo));
    console.log('eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee');
    console.log('^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^');
    console.log(' ');
    console.log(' ');
    console.log(' ');

    assetInfo.MarkAsUploaded = true;
    // for debug
    assetInfo.InjectError = rootState.uploader.InjectError;
    dispatch('markPreviewAsUploaded', assetInfo).then((serverAssetInfo) => {
      assetInfo.status_display = 'Updated';
      assetInfo.Status = serverAssetInfo.Status;

      if (assetInfo.FoundPreviewAssetInfos) {
        assetInfo.FoundPreviewAssetInfos.forEach((fpai) => {
          fpai.status_display = 'Associated';
          fpai.Status = serverAssetInfo.Status;
        });
      }

      if (serverAssetInfo.HasError) {
        commit('common/addAssetError', assetInfo, { root: true });
        assetInfo.Error += serverAssetInfo.Error;
        assetInfo.HasError = true;
      }
      rootState.uploader.numberNotMarkedUploadedInDb -= 1;
      dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Update complete!', errorMessage: 'There are some errors updating meatadata.', isEditMetadata: true });
    }).catch((error) => {
      // console.log(error);
      commit('common/addAssetError', assetInfo, { root: true });
      assetInfo.Status = 'error';
      assetInfo.Error += error.toString();
      assetInfo.HasError = true;
      rootState.uploader.numberNotMarkedUploadedInDb -= 1;
      dispatch('checkRegisterUploadMarkingStatus', { successMessage: '<h4>Upload complete!</h4>Files uploaded successfully will be processed, indexed and made available on the discovery portal. Processing times can vary based on file types and sizes.', errorMessage: 'There are some errors processing your files, please see details above. Files uploaded successfully will be processed, indexed and made available on the Discovery portal. Processing times can vary based on file types and sizes.', isEditMetadata: true });
    });
  },
  async initiateThumbnailingProcess({ commit }, assetId) {
    const data = await httpClient.initiateThumbnailingProcess(assetId);
    console.log(data);
    return data;
  },
};
