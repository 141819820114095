export const RightPanels = {
  SHARE: 'share',
  EDIT_COLLECTION_DETAILS: 'collection_details',
  GROUP: 'group',
  UPLOADER: 'uploader',
  ADD_TO_COLLECTIONS: 'add_to_collections',
  EDIT_METADATA: 'edit_metadata',
  NOTIFICATION_PANEL: 'notification_panel',
  COLLECTION_MEMBERS_PANEL: 'collection_members_panel',
  GROUP_MEMBERS_PANEL: 'group_members_panel',
  ASSET_TAGS: 'assetTags',
  ASSET_INSIGHT_CHARTS: 'assetInsightCharts',
  PAGE_CUSTOMIZATIONS: 'page_customizations',
  ASSIGN_TO_USERS: 'assign_to_users',
  REPLACE_FILE: 'replace_file',
  FILE_DETAILS: 'file_details',
  ASSET_IMAGERY: 'asset_imagery',
  PREFERENCES_PANEL: 'preferences_panel',
  ASK_BOT: 'ask_bot',
  ASK_BOT_RAG: 'ask_bot_rag',
  AI_WRITING_ASSISTANT_CASE_STUDY: 'ai_writing_assistant_case_study',
  AI_WRITING_ASSISTANT_CASE_STUDY_TRY: 'ai_writing_assistant_case_study_try',
  AI_WRITING_ASSISTANT_RESPONSE: 'ai_writing_assistant_response',
  AI_WRITING_ASSISTANT_TOOLS: 'ai_writing_assistant_tools',
  AI_CONTENT_SUMMARY: 'ai_content_summary',
  AI_THEME_EXTRATOR: 'ai_theme_extractor',
  AI_TONE_EXTRATOR: 'ai_tone_extractor',
  AI_PREVIEW_ANALYZER: 'ai_preview_analyzer',
  AI_SLIDE_PRESENTATION: 'ai_slide_presentation',
  AI_NEWSLETTER: 'ai_newsletter',
  AI_SOCIAL_POST: 'ai_social_post',
  AI_IMAGE_ANALYZER: 'ai_image_analyzer',
  AI_IMAGE_ALT_TAG_GENERATOR: 'ai_image_alt_tag_generator',
  AI_IMAGE_CAPTION_GENERATOR: 'ai_image_caption_generator',
  AUTOMATED_INSIGHTS: 'automated_insights',
  AWARD_DETAILS: 'award_details',
  COLLECTION_GROUP_SHARE: 'collection_group_share',
  AI_DOCUMENT_ANALYZER: 'ai_document_analyzer',
};

class RightPanelFactory {
  panels = {
    share: () => import('@/components/share/SharePanelWithBar.vue'),
    collection_details: () => import('@/components/common/panels/CollectionGroupEditorPanel.vue'),
    group: () => import('@/components/groups/panels/EditGroupPanel.vue'),
    uploader: () => import('@/components/uploader/Uploader.vue'),
    add_to_collections: () => import('@/components/uploader/CollectionAsset.vue'),
    edit_metadata: () => import('@/components/uploader/Metadata.vue'),
    notification_panel: () => import('@/components/notifications/NotificationPanel.vue'),
    collection_members_panel: () => import('@/components/common/panels/CollectionMembersPanel.vue'),
    group_members_panel: () => import('@/components/common/panels/GroupMembersPanel.vue'),
    assetTags: () => import('@/components/content/analyzer/panels/AssetTagsPanel.vue'),
    assetInsightCharts: () => import('@/components/content/analyzer/panels/AssetChartsPanel.vue'),
    page_customizations: () => import('@/components/common/panels/PageCustomizations.vue'),
    assign_to_users: () => import('@/components/assets/AssignUsersPanel.vue'),
    replace_file: () => import('@/components/uploader/ReplaceFile.vue'),
    file_details: () => import('@/components/common/panels/FileDetailsPanel.vue'),
    asset_imagery: () => import('@/components/assets/panels/AssetImageryPanel.vue'),
    preferences_panel: () => import('@/components/common/panels/PreferencesPanel.vue'),
    ask_bot_rag: () => import('@/openai/components/panels/AskBotRAG.vue'),
    ask_bot: () => import('@/openai/components/panels/AskBot.vue'),
    ai_writing_assistant_case_study: () => import('@/openai/components/panels/AIWriterCaseStudy.vue'),
    ai_writing_assistant_case_study_try: () => import('@/openai/components/panels/AIWriterCaseStudyTry.vue'),
    ai_writing_assistant_response: () => import('@/openai/components/panels/AIWriterResponse.vue'),
    ai_writing_assistant_tools: () => import('@/openai/components/panels/AIWriterTools.vue'),
    ai_content_summary: () => import('@/openai/components/panels/AIContentSummary.vue'),
    ai_slide_presentation: () => import('@/openai/components/panels/AISlidePresentation.vue'),
    ai_newsletter: () => import('@/openai/components/panels/AINewsletter.vue'),
    ai_theme_extractor: () => import('@/openai/components/panels/AIThemeExtractor.vue'),
    ai_tone_extractor: () => import('@/openai/components/panels/AIToneExtractor.vue'),
    ai_preview_analyzer: () => import('@/openai/components/panels/AIPreviewAnalyzer.vue'),
    ai_social_post: () => import('@/openai/components/panels/AISocialPost.vue'),
    ai_image_analyzer: () => import('@/openai/components/panels/AIImageAnalyzer.vue'),
    ai_image_alt_tag_generator: () => import('@/openai/components/panels/AIImageAltTagGenerator.vue'),
    ai_image_caption_generator: () => import('@/openai/components/panels/AIImageCaptionGenerator.vue'),
    automated_insights: () => import('@/openai/components/panels/AIInsights.vue'),
    award_details: () => import('@/components/assets/panels/AwardDetailsPanel.vue'),
    collection_group_share: () => import('@/components/share/CollectionGroupSharePanel.vue'),
    ai_document_analyzer: () => import('@/openai/components/panels/AIDocumentAnalyzer.vue'),
  }

  titles = {
    share: 'SHARE',
    collection_details: 'EDIT DETAILS',
    group: 'GROUP',
    uploader: 'UPLOADER',
    add_to_collections: 'ADD TO COLLECTIONS',
    edit_metadata: 'EDIT METADATA',
    notification_panel: 'NOTIFICATION',
    collection_members_panel: 'Collection Members',
    group_members_panel: 'Collection Members',
    assetTags: 'Tag',
    assetInsightCharts: 'Chart',
    page_customizations: 'Page Customizations',
    assign_to_users: 'Assign Users',
    replace_file: 'Replace File',
    file_details: 'File Details',
    asset_imagery: 'Asset Imagery',
    preferences_panel: 'Preferences',
    ask_bot: 'Ask Bot',
    ask_bot_rag: 'Ask Bot RAG',
    ai_writing_assistant_case_study: 'AI Case Study Writer',
    ai_writing_assistant_case_study_try: 'AI Writing Assistant',
    ai_writing_assistant_response: 'AI Writing Assistant',
    ai_writing_assistant_tools: 'AI Writing Assistant',
    ai_content_summary: 'AI Content Summmary',
    ai_theme_extractor: 'AI Theme Extractor',
    ai_tone_extractor: 'AI Tone Extractor',
    ai_preview_analyzer: 'AI Preview Analyzer',
    ai_slide_presentation: 'AI Slide Presentation',
    ai_newsletter: 'AI Newsletter Copy Generator',
    ai_social_post: 'AI Social Post Copy Generator',
    ai_image_alt_tag_generator: 'AI Image ALT Tag Generator',
    ai_image_caption_generator: 'AI Image Caption Generator',
    automated_insights: 'Automated Insights',
    award_details: 'Award Details',
    collection_group_share: 'SHARE',
  }

  icons = {
    share: 'mdi-share-variant',
    collection_details: 'mdi-pencil-outline',
    group: 'mdi-pencil-outline',
    uploader: 'mdi-upload',
    add_to_collections: 'mdi-folder-plus-outline',
    edit_metadata: 'mdi-tag-outline',
    notification_panel: 'mdi-bell',
    collection_members_panel: 'group',
    group_members_panel: 'group',
    assetTags: 'mdi-tag-multiple-outline',
    assetInsightCharts: 'mdi-tag-multiple-outline',
    page_customizations: 'mdi-pencil-outline',
    assign_to_users: 'mdi-account',
    replace_file: 'mdi-replace-file-outline',
    file_details: 'mdi-information-outline',
    preferences_panel: 'mdi-tune',
    ask_bot: '$chatGPT',
    ask_bot_rag: '$chatGPT',
    ai_writing_assistant_case_study: '$chatGPT',
    ai_writing_assistant_case_study_try: '$chatGPT',
    ai_writing_assistant_response: '$chatGPT',
    ai_writing_assistant_tools: '$chatGPT',
    ai_content_summary: '$chatGPT',
    ai_theme_extractor: '$chatGPT',
    ai_tone_extractor: '$chatGPT',
    ai_preview_analyzer: '$chatGPT',
    ai_slide_presentation: '$chatGPT',
    ai_newsletter: '$chatGPT',
    ai_social_post: '$chatGPT',
    ai_image_alt_tag_generator: '$chatGPT',
    ai_image_caption_generator: '$chatGPT',
    automated_insights: '$chatGPT',
    award_details: 'trophy',
    collection_group_share: 'mdi-share-variant',
  }

  get(panel) {
    return this.panels[panel];
  }

  getTitle(panel) {
    return this.titles[panel];
  }

  getIcon(panel) {
    return this.icons[panel];
  }
}

export default RightPanelFactory;
