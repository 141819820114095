import { mapState } from 'vuex';
import ExternalUrlActionTypes from '@/components/content/analyzer/ExternalUrlActionTypes';

export default {
  computed: {
    ...mapState('discovery', [
      'assetExternalUrlResult',
      'assetExternalUrlLabelResult',
      'assetExternalUrlActionResult',
      'assetExternalUrlDescriptionResult',
    ]),

    assetExternalUrlAction() {
      const externalUrlAction = this.assetExternalUrlActionResult?.rows?.[0] ?? null;
      return externalUrlAction;
    },
  },
  methods: {
    isNewWindowAction() {
      return this.assetExternalUrlAction?.toLowerCase() === ExternalUrlActionTypes.NEW_WINDOW;
    },

    assetExternalUrl() {
      const externalUrl = this.assetExternalUrlResult?.rows?.[0] ?? null;
      return externalUrl;
    },

    assetEmbedRoute(docId) {
      const { debug } = this.$route.query;
      const route = { name: 'asset-embed' };
      const getRoute = (isDebug) => ({ ...route, query: { docId } });

      return getRoute(debug);
    },
  },
};
