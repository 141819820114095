import RequestAccessForm from '@/lib/requestAccess/models/RequestAccessForm';
import formFieldNames from '@/lib/requestAccess/models/formFieldNames';

const formUrl = 'https://app.smartsheet.com/b/form/336494748939406fbe4b99fae2d80c2c';
class PrivateAssetRequestAccessForm extends RequestAccessForm {
  constructor() {
    super(formUrl);
  }

  set contentPageUrl(value) {
    this.pageURL = value;
  }
}

export default PrivateAssetRequestAccessForm;
