export const DiscoveryDialogTypes = {
  ADD_COLLECTIONS_TO_GROUP: 'addCollectionsToGroup',
  ASSOCIATE_COLLECTION_TO_GROUPS: 'associateCollectionToGroups',
  PREVIEW: 'preview',
  INSIGHT: 'insight',
  VIDEO_INDEXER_INSIGHT: 'videoIndexerInsight',
  EDIT_DOCUMENT: 'editDocument',
  EDIT_ASSET_PREVIEW: 'editAssetPreview',
  DELETE_DOCUMENT: 'deleteDocument',
  DELETE_COLLECTION: 'deleteCollection',
  PRIVATE_ASSET: 'privateAssetDialog',
  MY_SITES_SELECTOR: 'mySitesSelector',
  DELETE_GROUP: 'deleteGroup',
  REPORT_AN_ISSUE: 'reportAnIssue',
  REQUEST_ACCESS: 'requestAccess',
  POWERBI_EMBED_ERROR: 'powerbiEmbedError',
};

const DiscoveryDialogsFactory = function DiscoveryDialogsFactory(options) {
  this.default = {
    addCollectionsToGroup: {
      component: () => import('@/components/groups/dialogs/AddCollectionsToGroup.vue'),
    },
    associateCollectionToGroups: {
      component: () => import('@/components/groups/dialogs/AssociateCollectionToGroups.vue'),
    },
    preview: {
      component: () => import('@/components/content/preview/PreviewModalContent.vue'),
      width: '95%',
    },
    insight: {
      component: () => import('@/components/content/insight/ContentInsightModalContent.vue'),
      width: '90vw',
    },
    videoIndexerInsight: {
      component: () => import('@/components/content/video/VideoIndexerInsights.vue'),
      width: '90vw',
    },
    editDocument: {
      component: () => import('@/components/assets/dialogs/EditAsset.vue'),
    },
    editAssetPreview: {
      component: () => import('@/components/assets/dialogs/EditAssetPreview.vue'),
    },
    deleteDocument: {
      component: () => import('@/components/assets/dialogs/DeleteAssetDialog.vue'),
      width: '40%',
    },
    deleteCollection: {
      component: () => import('@/components/collections/dialogs/DeleteCollectionDialog.vue'),
      width: '40%',
    },
    privateAssetDialog: {
      component: () => import('@/components/assets/dialogs/PrivateAssetDialog.vue'),
      width: '40%',
    },
    mySitesSelector: {
      component: () => import('@/components/deployments/MySitesSelector.vue'),
      width: '450px',
    },
    deleteGroup: {
      component: () => import('@/components/groups/dialogs/DeleteGroupDialog.vue'),
      width: '450px',
    },
    reportAnIssue: {
      component: () => import('@/components/common/dialogs/ReportAnIssueDialog.vue'),
      width: '40%',
    },
    requestAccess: {
      component: () => import('@/components/common/dialogs/RequestAccess.vue'),
      width: '700px',
    },
    powerbiEmbedError: {
      component: () => import('@/components/content/analyzer/dialogs/PowerBiErrorDialog.vue'),
      width: '700px',
    },
  };

  this.dialogs = { ...this.default, ...options };
};

DiscoveryDialogsFactory.prototype.get = function getPanel(type) {
  if (!(type in this.dialogs)) {
    return null;
  }

  return this.dialogs[type];
};

export default DiscoveryDialogsFactory;

export const discoveryDialogFactory = (options) => new DiscoveryDialogsFactory(options);
