export default {
  children: [
    {
      path: 'home',
      name: 'groups-home',
      component: () => import(
        /* webpackChunkName: "groups-home" */
        '../../components/groups/page/GroupsHomePage.vue'
      ),
      meta: {
        listType: 'default',
        mobileName: 'groups',
      },
    },
    {
      path: 'my',
      name: 'my-groups',
      component: () => import(
        /* webpackChunkName: "groups-sub-page" */
        '../../components/groups/page/GroupsSubPage.vue'
      ),
      meta: {
        listType: 'my',
        mobileName: 'groups',
        pageTitle: 'My Groups',
      },
    },
    {
      path: 'shared',
      name: 'shared-groups',
      component: () => import(
        /* webpackChunkName: "groups-sub-page" */
        '../../components/groups/page/GroupsSubPage.vue'
      ),
      meta: {
        listType: 'shared',
        mobileName: 'groups',
        pageTitle: 'Shared Groups',
      },
    },
    {
      path: 'all',
      name: 'all-groups',
      component: () => import(
        /* webpackChunkName: "groups-sub-page" */
        '../../components/groups/page/GroupsSubPage.vue'
      ),
      meta: {
        listType: 'all',
        mobileName: 'groups',
        pageTitle: 'All Groups',
      },
    },
    {
      path: 'pinned',
      name: 'pinned-groups',
      component: () => import(
        '../../components/groups/page/GroupsSubPage.vue'
      ),
      meta: {
        listType: 'pinned',
        mobileName: 'groups',
        pageTitle: 'Pinned Groups',
      },
    },
    {
      path: 'featured',
      name: 'featured-groups',
      component: () => import(
        /* webpackChunkName: "groups-sub-page" */
        '../../components/groups/page/GroupsSubPage.vue'
      ),
      meta: {
        listType: 'featured',
        mobileName: 'groups',
        pageTitle: 'Featured Groups',
      },
    },
    {
      path: 'starred',
      name: 'starred-groups',
      component: () => import(
        '../../components/groups/page/GroupsSubPage.vue'
      ),
      meta: {
        listType: 'starred',
        mobileName: 'groups',
        pageTitle: 'Starred Groups',
      },
    },
  ],
};
