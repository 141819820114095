class PowerBIEmbedTokenResult {
  reportId = '';

  embedToken = '';

  error = null;

  embedUrl = '';

  /**
   *
   * @param reportId{string}
   * @param embedToken{string}
   * @param error{string|null}
   */
  constructor(reportId = '', embedUrl = '', embedToken = '', error = null) {
    this.reportId = reportId;
    this.embedToken = embedToken;
    this.embedUrl = embedUrl;
    this.error = error;
  }

  static fromObject({
    reportId, token, embedUrl, error = null,
  }) {
    return new PowerBIEmbedTokenResult(reportId, embedUrl, token, error);
  }
}

export default PowerBIEmbedTokenResult;
