import {
  mapActions, mapGetters, mapState,
} from 'vuex';
import previewLinkMixin from '@/components/mixins/previewLinkMixin';
import { DashboardIssueForm, ReportAnIssueForm } from '@/lib/requestAccess/models';
import DiscoveryDialogOptions from '@/components/ui/dialogs/DiscoveryDialogOptions';
import { DiscoveryDialogTypes } from '@/lib/ui/dialogs/DiscoveryDialogsFactory';
import smartSheetFormsMixin from '@/components/mixins/smartSheetFormsPopupMixin';

export default {
  mixins: [
    smartSheetFormsMixin,
  ],
  computed: {
    ...mapState('discovery', [
      'currentDocument',
    ]),
    ...mapGetters('common', [
      'userEmail',
    ]),

  },
  methods: {
    ...mapActions('common', [
      'openDialog',
    ]),
    reportAnIssue(form = null) {
      const formFactory = () => {
        if (form != null) {
          return form;
        }

        const issueForm = new ReportAnIssueForm();
        issueForm.email = this.userEmail;
        issueForm.pageUrl = this.currentPageUrl;
        issueForm.title = 'Report An Issue';

        return issueForm;
      };

      this.openRequestAccessForm(formFactory());
    },
  },
};
