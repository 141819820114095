export default {

  uploaderInitialized: false,

  metadataSets: [],
  deploymentMetadataSet: { SetFields: [] },
  indexMetadataSet: { setFields: [], setFieldValues: [] },
  metadataSetData: { setId: null, setFields: [], setFieldValues: [] },

  selectedSubsetId: null,
  selectedMasterSetId: null,
  selectedDocId: null,
  selectedSetFieldValues: [],
  resetSelections: true,

  uploadFiles: [],
  uploadPreviewFiles: [],
  assetInfos: [],
  previewAssetInfos: [],
  uploadedAmount: 0, // mb
  uploadedProgress: 0, // mb

  totalFilesize: 0,
  totalUploadedBytes: 0,
  totalProgress: 0,
  newNumberUploadInProgresss: 0,

  editMetatadataAssetInfo: {},

  selectedAssets: [],

  uploadToCollection: false,

  numberUploads: 0,
  numberUploadInProgresss: 0,
  numberNotMarkedUploadedInDb: 0,
  // only used on EditMetadata at moment
  numberPreviewUploads: 0,
  numberPreviewUploadInProgresss: 0,

  BulkError: false,
  RegisterError: false,
  MarkAsUploadError: false,
  FakeErrorMessage: 'Fake message from UI',

  blobService: null,
  blobServiceClient: null,
  sasConnectionString: null,
  uploaderPanelOpened: false,
  addToCollectionPanelOpened: false,
  savedSfvs: [],
};
