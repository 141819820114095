import RequestAccessForm from '@/lib/requestAccess/models/RequestAccessForm';

const form = 'https://app.smartsheet.com/b/form/15d9ae0fce0b4d48a61b8b2592d73032';
class NewContentRequestForm extends RequestAccessForm {
  constructor() {
    super(form);
  }
}

export default NewContentRequestForm;
