class SystemMessage {
  topic = '';

  message = '';

  persistent = false;

  constructor(topic, message, persistent = false) {
    this.topic = topic;
    this.message = message;
    this.persistent = persistent;
  }
}

export default SystemMessage;
