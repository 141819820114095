import {
  DashboardRequestAccessForm,
  PrivateAssetRequestAccessForm,
} from '@/lib/requestAccess/models';

export const PrivateAssetDialogTypes = {
  PRIVATE_ASSET: 'privateAsset',
  DASHBOARD_ACCESS: 'dashboardAccess',
};

const getTitle = (type) => {
  if (type === PrivateAssetDialogTypes.PRIVATE_ASSET) {
    return 'Private Asset';
  }

  if (type === PrivateAssetDialogTypes.DASHBOARD_ACCESS) {
    return 'Dashboard Access';
  }

  return '';
};

const getMessage = (type) => {
  if (type === PrivateAssetDialogTypes.PRIVATE_ASSET) {
    return 'Sorry, you do not have permission to access this content.';
  }

  return 'Please follow the below instructions to request access to this dashboard.';
};

const createAccessRequestForm = (type) => {
  if (type === PrivateAssetDialogTypes.PRIVATE_ASSET) {
    return new PrivateAssetRequestAccessForm();
  }

  return new DashboardRequestAccessForm();
};

class PrivateAssetDialogOptions {
  /**
   *
   * @type {Asset}
   */
  asset = null;

  /**
   *
   * @type {RequestAccessForm}
   */
  requestAccessForm = null;

  type = PrivateAssetDialogTypes.PRIVATE_ASSET;

  /**
   *
   * @param asset{Asset}
   */
  constructor(asset, type) {
    this.asset = asset;
    this.type = type;
  }

  get dialogTitle() {
    return getTitle(this.type);
  }

  get message() {
    return getMessage(this.type);
  }

  createAccessRequestForm() {
    return createAccessRequestForm(this.type);
  }
}

export default PrivateAssetDialogOptions;
