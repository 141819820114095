import helper from '@/lib/util/dataSimulator/helper';

const extractNameAndEmail = (userInfo) => {
  const regexp = /(?<full>(?<givenName>.*?)(?<lastName>\s+.*?)?)<(?<email>.*?)>/;
  const match = userInfo.match(regexp);

  if (match == null) {
    return null;
  }

  const {
    givenName,
    lastName,
    email,
  } = match?.groups;

  return { givenName, lastName, email };
};

const extractFromEmail = (input) => ({
  givenName: input,
  lastName: '',
  email: input,
});

class User {
  userId = 0;

  givenName = '';

  lastName = '';

  email = '';

  avatarColor = '';

  role = '';

  static RoleCodes = {
    AUTHOR: 'Author',
    CONTRIBUTOR: 'Contributor',
    KEY_CONTACT: 'Key Contact',
    STAKEHOLDER: 'Stakeholder',
    SUPPORT_CONTACT: 'Support Contact',
    DASHBOARD_ACCESS: 'Dashboard Access',
    DATA_GOVERNANCE: 'Data Governance',
  };

  constructor(user) {
    if (user == null) {
      return;
    }
    this.userId = user.userId;
    this.givenName = user.givenName.trim();
    this.lastName = user.lastName.trim();
    this.email = user.email;
    this.role = user.role;
  }

  get fullName() {
    return `${this.givenName} ${this.lastName}`.trim();
  }

  /**
   *
   * @returns {function(*=): User}
   */
  static factory() {
    return (user) => new User(user);
  }

  static fromIndexUser(userInfo, role) {
    const {
      givenName,
      lastName,
      email,
    } = extractNameAndEmail(userInfo) || extractFromEmail(userInfo);

    return new User({
      givenName,
      lastName,
      email,
      id: email,
      role: role,
    });
  }
}

export default User;

/**
 *
 * @constructor
 */
const UserFactory = function uf() {
  this.cache = {};
};

const avatarColors = [
  '#42B777',
  '#88E6E6',
  '#198BB4',
  '#8da290',
  '#f2cbbb',
];

/**
 *
 * @param user
 * @returns {*|User}
 */
UserFactory.prototype.create = function createUser(user) {
  if (!user) {
    return null;
  }

  const { userId, email } = user;

  if (userId <= 0 || email == null || email === '') {
    return null;
  }

  const key = userId || email;

  if (!this.cache[key]) {
    const newUser = new User(user);
    const color = helper.getRandomInt(0, avatarColors.length);
    newUser.avatarColor = avatarColors[color];

    if (newUser != null) {
      this.cache[key] = newUser;
    }
  }
  return this.cache[key];
};

UserFactory.prototype.createFromGraphDb = function createFromGraphDb(user) {
  const [givenName, lastName] = user.name.split(' ');
  return this.create({ ...user, givenName, lastName });
};

/**
 *
 * @param list
 * @returns {*[]}
 */
UserFactory.prototype.createUsers = function createUsers(list) {
  if (!list || list.length === 0) {
    return [];
  }

  const result = [];

  list.forEach((user) => {
    const newUser = this.create(user);
    if (newUser != null) {
      result.push(newUser);
    }
  });

  return result;
};

export const userFactory = new UserFactory();
