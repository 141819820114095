export default {
  USER_EMAIL: 'User Email',
  EMAIL: 'Email',
  SITE: 'Site',
  DASHBOARD_INFORMATION: 'Dashboard Information',
  CONTENT: 'Content Information',
  PAGE_URL: 'Page URL',
  TYPE: 'Type',
  DESCRIPTION: 'Description',
  BUSINESS_AREA: 'Business Area',
};
