<template>
  <v-system-bar
    v-if="showAlert && $vuetify.breakpoint.smAndUp"
    app
    height="60px"
    class="justify-space-between pl-16 pr-4 confidential_alert text-body-1">
    <div class="confidential_alert" style="padding-left:6px"><strong v-if="topic">{{ topic }}: </strong>{{ message }}</div>
    <v-btn width="48" height="48" icon @click="closeSystemMessageBar">
      <v-icon class="material-symbols-outlined mr-0">close</v-icon>
    </v-btn>
    </v-system-bar>
</template>

<script>
import SystemMessage from '@/lib/systemAlerts/SystemMessage';

export default {
  name: 'SystemMessageDisplay',
  props: {
    systemMessage: {
      type: SystemMessage,
    },
    show: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    topic() {
      return this.systemMessage?.topic;
    },
    message() {
      return this.systemMessage?.message;
    },
    showAlert: {
      get() {
        return this.show;
      },
      set(value) {
        this.$emit('update:show', value);
      },
    },
  },
  methods: {
    closeSystemMessageBar() {
      this.showAlert = false;
    },
  },
};
</script>

<style scoped>

</style>
