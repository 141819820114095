import {
  mapActions,
  mapGetters,
  mapState,
} from 'vuex';

import previewLinkMixin from '@/components/mixins/previewLinkMixin';
import DiscoveryDialogOptions from '@/components/ui/dialogs/DiscoveryDialogOptions';
import { DiscoveryDialogTypes } from '@/lib/ui/dialogs/DiscoveryDialogsFactory';
import {
  DashboardIssueForm,
  DashboardRequestAccessForm,
  NewContentRequestForm,
  PrivateAssetRequestAccessForm,
  ReportAnIssueForm,
} from '@/lib/requestAccess/models';
import assetInfoMixin from '@/components/content/mixins/assetInfoMixin';
import SuggestATagForm from '@/lib/requestAccess/models/SuggestATagForm';

export default {
  mixins: [
    previewLinkMixin,
    assetInfoMixin,
  ],
  computed: {
    ...mapGetters('common', [
      'userEmail',
      'selectedDeploymentIndex',
    ]),

    indexName() {
      return this.selectedDeploymentIndex.name;
    },
    currentRoute() {
      if (this.isThisEmbedPage) {
        return this.contentPageRoute;
      }

      return {
        name: this.$route.name,
        params: this.$route.params,
        query: this.$route.query,
      };
    },
    currentPageUrl() {
      const route = this.currentRoute;
      return this.previewLink(route);
    },
  },
  methods: {
    ...mapActions('common', [
      'openDialog',
    ]),
    /**
     *
     * @param form{RequestAccessForm}
     */
    openRequestAccessForm(form) {
      const options = new DiscoveryDialogOptions(
        DiscoveryDialogTypes.REQUEST_ACCESS,
        form,
        () => console.log('Closed Report an Issue'),
      );

      this.openDialog(options);
    },
    createDashboardRequestAccessForm() {
      const form = new DashboardRequestAccessForm();
      form.site = this.indexName;
      form.email = this.userEmail;

      return form;
    },
    /**
     *
     * @param form {RequestAccessForm}
     */
    setCommonDetails(form) {
      form.email = this.userEmail;
      form.site = this.indexName;
      form.pageURL = this.currentPageUrl;

      return form;
    },
    openDashboardIssueForm(extra = null) {
      const form = new DashboardIssueForm();
      form.extraInfo = extra;
      this.setCommonDetails(form);
      form.description = this.getDashboardInfo(this.currentPageUrl);

      this.openRequestAccessForm(form);
    },
    openDashboardRequestAccessForm() {
      const form = new DashboardRequestAccessForm();

      form.dashboardName = this.getDashboardInfo(this.currentPageUrl);
      form.businessArea = this.dashboardCategory;
      this.setCommonDetails(form);
      this.openRequestAccessForm(form);
    },
    openPrivateAssetRequestForm() {
      console.log('opening openPrivateAssetRequestForm');
      const form = new PrivateAssetRequestAccessForm();
      form.description = this.getAssetInfo(this.currentPageUrl);
      this.setCommonDetails(form);
      this.openRequestAccessForm(form);
    },
    openSuggestATagForm() {
      const form = this.setCommonDetails(new SuggestATagForm());
      this.openRequestAccessForm(form);
    },
    openReportAnIssueForm() {
      console.log('Ouvre la openReportAnIssueForm');
      const form = this.setCommonDetails(new ReportAnIssueForm());
      form.setData('Type', 'Issue');
      this.openRequestAccessForm(form);
    },
    openNewContentRequestForm() {
      const form = this.setCommonDetails(new NewContentRequestForm());
      this.openRequestAccessForm(form);
    },
  },
};
