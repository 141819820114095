import { deploymentIndexListFactory } from '@/lib/deployments/models/DeploymentIndex';
import colorPalletes from '@/lib/ui/colorPalletes';

const colorFactory = {
  1: '#7c65a5',
  2: '#FF8002',
  3: '#7c65a5',
  4: '#FF8002',
  5: '#7c65a5',
};

const chartColors = [
  '#DAD2E6',
  '#BFC4FF',
  '#B3D4FF',
  '#C5FFE9',
  '#D7C7EC',
  '#FBD1EA',
  '#E0F3B8',
  '#FFDCCE',
  '#D2EEFD',
  '#FAC8AD',
  '#E5ADB2',
  '#C5ADB4',
  '#FAB4DF',
  '#FAE9ED',
];

const primaryColor = '#DAD2E6';

const Deployment = function Deployment(data) {
  this.deploymentId = data?.deploymentId;
  this.name = data?.name;
  this.isCurrent = data?.isCurrent;
  this.metadataSetId = data?.metadataSetId;

  /**
   *
   */
  this.deploymentIndexes = (data
    && data.deploymentIndexes
    && deploymentIndexListFactory(data.deploymentIndexes)
  );

  /**
   *
   * @type {DeploymentIndex}
   */
  this.currentIndex = null;
  this.primaryColor = colorFactory[this.deploymentId] || colorFactory[1];
  this.setCurrentIndex(null);
};

/**
 *
 * @param index {DeploymentIndex}
 * @returns {Deployment}
 */
Deployment.prototype.setCurrentIndex = function setCurrentIndex(index) {
  if (this.currentIndex !== null) {
    this.currentIndex.isCurrent = false;
  }

  this.currentIndex = (
    index
    || this.deploymentIndexes?.find((i) => i.isCurrent)
    || (this.deploymentIndexes && this.deploymentIndexes[0])
  );

  if (this.currentIndex != null) {
    this.currentIndex.isCurrent = true;
  }

  return this;
};

Deployment.prototype.setIsCurrent = function setIsCurrent() {
  this.isCurrent = this.deploymentIndexes?.some((index) => index.isCurrent);
};

Deployment.prototype.unsetIsCurrent = function unsetIsCurrent() {
  this.isCurrent = false;
  if (this.currentIndex != null) {
    this.currentIndex.isCurrent = false;
  }
};

Deployment.prototype.getPalette = function getPalette(count) {
  console.log('Deployment', this);
  const color = this.primaryColor;
  return colorPalletes.getPalette(color)(count);
};

Deployment.prototype.paletteFactory = function paletteFactory() {
  return colorPalletes.getPalette({
    primaryColor,
    colors: chartColors,
  });
};

export default Deployment;

export const deploymentFactory = (data) => new Deployment(data);
export const deploymentListFactory = (list) => list && list.map(deploymentFactory);
