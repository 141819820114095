import PowerBIEmbedTokenResult
from '@/lib/dataVisualizations/powerBI/models/PowerBIEmbedTokenResult';
import {
  interval, switchMap, takeWhile, tap,
} from 'rxjs';

class PowerBIClient {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  embedUrlRoot = 'https://app.powerbi.com/reportEmbed?reportId=';

  constructor(http) {
    this.http = http;
    this.endpoint = 'v2/powerbi';
  }

  /**
   *
   * @param reportUrl{string}
   * @returns {Promise<PowerBIEmbedTokenResult>}
   */
  async getEmbedToken(reportUrl, docId) {
    console.log('Getting embed token', docId);
    const { data } = await this.http.post(`${this.endpoint}/embedToken`, {
      reportUrl,
      docId,
    });
    const result = PowerBIEmbedTokenResult.fromObject(data.data);

    return result;
  }

  getEmbedUrl(reportId) {
    return `${this.embedUrlRoot}${reportId}`;
  }

  async export(reportUrl, format, docId, progressCallback, onCompleteCallback) {
    const { data } = await this.http.post(`${this.endpoint}/export`, {
      reportUrl,
      format,
      docId,
    });

    const exportResult = data.data;

    if (exportResult.export?.status === 'Failed') {
      throw new Error('Export failed');
    }

    if (exportResult.export?.status === 'Succeeded') {
      onCompleteCallback(exportResult);
    }

    if (exportResult.export?.status === 'NotStarted' || exportResult.export?.status === 'Running') {
      const result = await this.checkExportStatus(reportUrl, exportResult.export?.id, progressCallback);
      console.log('Export complete', result);
      onCompleteCallback(result);
    }
  }

  /**
   *
   * @param reportUrl
   * @param exportId
   * @param progressCallback
   * @returns {Promise<unknown>}
   */
  async checkExportStatus(reportUrl, exportId, progressCallback) {
    const exportRequest = {
      reportUrl,
      exportId,
    };

    return new Promise((resolve) => {
      interval(2000)
        .pipe(
          switchMap(async () => {
            try {
              const { data } = await this.http.post(`${this.endpoint}/exportStatus`, exportRequest);
              return data.data;
            } catch (e) {
              console.log(e);
              return null;
            }
          }),
          tap((result) => {
            if (result.export?.status === 'Running') {
              progressCallback(result);
            }
          }),
          takeWhile((result) => (result.export?.status !== 'Succeeded' && result.export?.status !== 'Failed'), true),
        )
        .subscribe({
          next: (result) => {
            if (result.export?.status === 'Succeeded' || result.export?.status === 'Failed') {
              resolve(result);
            }
          },
          error: (err) => {
            console.log(err);
            resolve(null);
          },
        });
    });
  }
}

export default PowerBIClient;
