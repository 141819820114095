import moment from 'moment/moment';
import User, { userFactory } from '@/lib/business/models/User';
import fileTypesUtil from '@/lib/util/fileTypesUtil';
import { discoveryThemeWithNoThumbnail } from '@/lib/themes/DiscoveryTheme';
import collectionThemes from '@/lib/business/models/themes/collectionThumbnailProperties';
import SelectedSet from '@/lib/business/models/SelectedSet';
import InteractionInsight from '@/lib/insights/models/InteractionInsight';
import { shareableLocation } from '@/lib/util/shareables';
import PageActions from '@/lib/ui/models/PageActions';

const getPrimaryAuthor = (asset) => {
  const primaryContributor = asset?.assetContributors?.find(
    (contributor) => contributor.isPrimary,
  );

  if (primaryContributor != null) {
    return userFactory.create(primaryContributor.user);
  }

  return null;
};

class Asset {
  assetId = 0;

  id = 0;

  docId = '';

  tempDocId = '';

  title = '';

  description = '';

  fileName = '';

  originalFileName = '';

  storagePath = '';

  created = null;

  updated = '';

  deploymentIndexId = 0;

  /**
   *
   * @type {User}
   */
  createdByUser = null;

  /**
   *
   * @type {User}
   */
  primaryAuthor = null;

  url = '';

  calculateDocumentType = '';

  fileExtension = '';

  token = '';

  contributors = [];

  assetContributors = [];

  contentTypeName = null;

  createdByUserId = 0;

  updatedByUserId = 0;

  submissionDate = null;

  /**
   *
   * @type {String|null}
   */
  thumbnailUrl = null;

  thumbnailSasUrl = null;

  isImage = false;

  isVideo = false;

  showThumbnail = false;

  theme = null;

  selected = false;

  contentTypes = [];

  contentTypeGroups = [];

  fileExtensionFileTypeCategory = '';

  summary = '';

  summaryText = '';

  fullSizePreviewUrl = '';

  selectedSet = new SelectedSet();

  interactionInsight = new InteractionInsight();

  pinnedCollections = [];

  pageActions = new PageActions();

  isAccessible = true;

  defaultPdfPreview = null;

  fileSize = 0;

  customizationSettings = null;

  keyContact = null;

  keyContacts = [];

  userGroups = [];

  constructor(asset) {
    if (asset == null) {
      return;
    }

    this.assetId = asset.assetId;
    this.id = asset.assetId;

    this.docId = asset.docId;
    this.tempDocId = asset.tempDocId;
    this.fileName = asset.fileName;
    this.originalFileName = asset.originalFileName;
    this.storagePath = asset.storagePath;
    this.created = moment(asset.created);
    this.updated = moment(asset.updated);
    this.deploymentIndexId = asset.deploymentIndexId;

    this.createdByUser = userFactory.create(asset.createdByUser);
    this.primaryAuthor = getPrimaryAuthor(asset);
    this.createdByUserId = asset.createdByUserId;
    this.updatedByUserId = asset.updatedByUserId;

    if (asset.url == null) {
      this.url = `${asset.storagePath}${asset.token}`;
    } else {
      this.url = asset.url;
    }

    this.token = asset.token;
    this.title = asset.title;
    this.description = asset.description;

    this.contentTypeName = asset?.contentTypeName;
    this.thumbnailUrl = asset?.thumbnailUrl;
    this.thumbnailSasUrl = asset?.thumbnailSasUrl;

    this.submissionDate = asset?.submissionDate && moment(asset.submissionDate);

    this.setDocumentType();
    this.setFileExtension();
    this.setContributors(asset);
    this.setTheme(asset?.theme);

    if (asset.contentType != null) {
      this.contentTypeName = asset.contentType.name;
    }

    this.contentTypes = asset?.contentTypes || [];
    this.fileExtensionFileTypeCategory = asset?.fileExtensionFileTypeCategory || '';
    this.summary = asset?.summary || '';
    this.summaryText = asset?.summaryText || '';

    this.fullSizePreviewUrl = asset?.fullSizePreviewSasUrl || '';
    this.contentTypeGroups = asset?.contentTypeGroups || [];

    this.selectedSet = new SelectedSet(asset?.selectedSet);

    this.keyContacts = this.selectedSet?.getValue('key_contacts') || [];
    const [keyContact] = this.keyContacts;
    this.setKeyContact(keyContact);

    if (asset?.interactionInsight != null) {
      this.interactionInsight = InteractionInsight.fromObject(asset?.interactionInsight);
    }

    this.isAccessible = asset?.isAccessible;
    this.defaultPdfPreview = asset?.defaultPdfPreview;
    this.customizationSettings = null;
    this.userGroups = asset?.userGroups;
  }

  get id() {
    return this.docId;
  }

  get decodedPath() {
    return this.URL;
  }

  get filename() {
    return this.fileName;
  }

  get originalFilename() {
    return this.originalFileName || this.filename;
  }

  get documentType() {
    return this.contentTypeName || this.calculateDocumentType;
  }

  set documentType(value) {
    this.calculateDocumentType = value;
  }

  get URL() {
    if (this.defaultPdfPreview != null) {
      return `${this.defaultPdfPreview}${this.token}`;
    }
    return `${this.storagePath}${this.token}`;
  }

  get assetTitle() {
    return this.title || this.filename || '';
  }

  get synopsis() {
    return this.description;
  }

  get isVideo() {
    return fileTypesUtil.isVideo(this.fileExtension);
  }

  get fileType() {
    return this.fileExtension;
  }

  setCustomizationSettings(settings) {
    this.customizationSettings = settings;
  }

  setFileExtension() {
    if (!this.fileName || this.fileName === '') {
      return;
    }

    const extension = this.fileName.substring(this.fileName.lastIndexOf('.'));
    this.fileExtension = extension;
  }

  setKeyContact(contact) {
    if (contact != null) {
      const userinfo = User.fromIndexUser(contact);
      this.keyContact = userFactory.create(userinfo);
    }
  }

  /**
   * Content path looks like this.
   * https://cistrdev.blob.core.windows.net/ci-clean/Responses/.../FileName.pdf
   * To get the collection type, we ignore the host, and storage account name.
   * What we get is something like this
   * /Responses/.../FileName.pdf
   * the first element in this string is the collection type.
   */
  setDocumentType() {
    try {
      const url = new URL(this.storagePath);
      let [, , type] = url.pathname.split('/') || ['', '', ''];

      if (type === 'Cases') {
        type = 'Case Study';
      }
      this.calculateDocumentType = decodeURIComponent(type);
    } catch {
      this.calculateDocumentType = 'NA';
    }
  }

  setContributors(asset) {
    if (!asset) {
      return;
    }

    this.contributors = userFactory.createUsers(asset.contributors);
    this.assetContributors = userFactory.createUsers(asset.contributors);
  }

  get rawLocation() {
    return { name: 'content-page', params: { id: this.docId } };
  }

  shareableObject(preview = true) {
    return {
      name: this.fileName,
      type: 'Document',
      location: shareableLocation(this.rawLocation)(preview),
    };
  }

  setIsImageIsVideo() {
    this.isImage = fileTypesUtil.isImage(this.fileExtension);
    console.log(`${this.fileName} is image? ${this.isImage}`);
    if (!this.isImage) {
      this.isVideo = fileTypesUtil.isVideo(this.fileExtension);
      this.showThumbnail = this.thumbnailUrl != null;
    }
  }

  /**
   * We need the token when it's the asset. For generic images, we don't. they throw error
   * @param token
   */
  previewUrl(token) {
    if (this.thumbnailUrl == null) {
      return '';
    }

    if (this.thumbnailUrl.includes('ci-discovery-portal-ui')) {
      return this.thumbnailUrl;
    }

    return `${this.thumbnailUrl}${token}`;
  }

  setTheme(theme) {
    this.theme = discoveryThemeWithNoThumbnail(theme);
  }

  getMetadataFieldValue(fieldCode) {
    if (this.selectedSet == null) {
      return null;
    }

    const value = this.selectedSet.getValue(fieldCode);
    return value;
  }

  /**
   *
   * @param collectionIds{Array<int>}
   */
  addToPinnedCollections(collectionIds = []) {
    this.pinnedCollections = [...this.pinnedCollections, ...collectionIds];
    return this;
  }

  removeFromPinnedCollections(collectionId) {
    this.pinnedCollections = this.pinnedCollections
      .filter((pinnedCollectionId) => pinnedCollectionId !== collectionId);
  }

  isPinnedToCollection(collectionId) {
    console.log('Pinned collections', this.pinnedCollections);
    return this.pinnedCollections.includes(collectionId);
  }
}

Asset.prototype.theme = collectionThemes.defaultThemeProperty(false);

Object.defineProperty(Asset.prototype, 'name', {
  get() {
    return this.title;
  },
  set(value) {
    this.title = value;
  },
});

Object.defineProperty(Asset.prototype, 'hasMembers', {
  get() {
    return false;
  },
});

export default Asset;
