import formFieldNames from '@/lib/requestAccess/models/formFieldNames';

const createQueryString = (data) => {
  const searchParams = new URLSearchParams();

  Object.entries(data).forEach(([key, value]) => {
    searchParams.set(key, value);
  });

  return searchParams.toString();
};
class RequestAccessForm {
  title = 'Request Access';

  formUrl = '';

  formData = {};

  constructor(iframeUrl, formData = {}) {
    this.formUrl = iframeUrl;
    this.formData = formData;
  }

  get iframeURL() {
    const queryString = createQueryString(this.formData);
    return `${this.formUrl}?${queryString}`;
  }

  set email(value) {
    this.formData[formFieldNames.EMAIL] = value;
  }

  set site(value) {
    this.formData[formFieldNames.SITE] = value;
  }

  set pageURL(value) {
    this.formData[formFieldNames.PAGE_URL] = value;
  }

  set description(value) {
    this.formData[formFieldNames.DESCRIPTION] = value;
  }

  setData(fieldName, value) {
    this.formData[fieldName] = value;
  }
}

export default RequestAccessForm;
