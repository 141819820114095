import RequestAccessForm from '@/lib/requestAccess/models/RequestAccessForm';
import formFieldNames from '@/lib/requestAccess/models/formFieldNames';

const formUrl = 'https://app.smartsheet.com/b/form/ace53516854042d0b319271bc1eb8a0e';

const EMAIL_FIELD = 'User Email';
const SITE_FIELD = 'Site';
const DASHBOARD_FIELD = 'Dashboard Information';

class DashboardRequestAccessForm extends RequestAccessForm {
  constructor() {
    super(formUrl);
    this.title = 'Dashboard Access Request';
  }

  set dashboardName(value) {
    this.description = value;
    this.formData[formFieldNames.DASHBOARD_INFORMATION] = value;
  }

  set email(value) {
    this.formData[formFieldNames.USER_EMAIL] = value;
    super.email = value;
  }

  set type(value) {
    this.formData[formFieldNames.TYPE] = value;
  }

  set businessArea(value) {
    this.formData[formFieldNames.BUSINESS_AREA] = value;
  }
}

export default DashboardRequestAccessForm;
