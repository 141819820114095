import AppObjectSetting from '@/lib/deployments/models/AppObjectSetting';
import DeploymentIndex from '@/lib/deployments/models/DeploymentIndex';
import Collection from '@/lib/business/models/Collection';
import Group from '@/lib/business/models/groups/Group';
import Asset from '@/lib/business/models/Asset';
import FeaturedGroup from '@/lib/business/models/groups/FeaturedGroup';
import FeaturedCollection from '@/lib/business/models/collections/FeaturedCollection';
import AssetDisplayDetail from '../business/models/AssetDisplayDetail';

const endpointNameFactory = (entity) => {
  console.debug('Fethcing entity type fpr', entity);
  if (entity instanceof DeploymentIndex) {
    return 'deploymentIndexes';
  }

  if (entity instanceof Collection) {
    return 'collections';
  }

  if (entity instanceof FeaturedCollection) {
    return 'collections';
  }

  if (entity instanceof Group) {
    return 'groups';
  }

  if (entity instanceof FeaturedGroup) {
    return 'groups';
  }

  if (entity instanceof Asset || entity instanceof AssetDisplayDetail) {
    return 'assets';
  }

  console.error('Could not find customization entity type for', entity);
  return null;
};

const endpointFactory = (entity) => {
  console.log('Getting endpoint for ', entity);
  const endpointName = endpointNameFactory(entity);
  console.log(`Endpoint name ${endpointName}`);

  const { id } = entity;
  const url = `v2/${endpointName}/${id}/customization`;
  return url;
};

class CustomizationsClient {
  /**
   *
   * @type {AxiosInstance}
   */
  http = null;

  /**
   *
   * @param http{AxiosInstance}
   */
  constructor(http) {
    this.http = http;
  }

  async getCustomizations(entity) {
    const url = endpointFactory(entity);
    const { data } = await this.http.get(url);
    const object = AppObjectSetting.fromObject(data);
    entity.setCustomizationSettings(object);
  }

  async getCustomizationsList(entitiesList) {
    const ids = entitiesList.map((item) => item.id);
    const entityType = endpointNameFactory(entitiesList[0]);
    const url = `v2/${entityType}/customizations`;
    const { data } = await this.http.post(url, ids);
    const settings = AppObjectSetting.fromObjects(data);
    entitiesList.map((entity, index) => entity.setCustomizationSettings(settings[index]));
  }

  async getPropertySetting(entities, propertyCode) {
    const ids = entities.map((item) => item.id);
    const entity = entities[0];
    const entityType = endpointNameFactory(entity);
    const url = `v2/${entityType}/customization/${propertyCode}`;
    const { data } = await this.http.post(url, ids);

    return data;
  }

  async saveCustomizations(entity) {
    const url = endpointFactory(entity);
    const { data } = await this.http.put(url, entity?.customizationSettings);
    const object = AppObjectSetting.fromObject(data);
    entity.setCustomizationSettings(object);
  }
}

export default CustomizationsClient;
