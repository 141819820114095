import DashboardRequestAccessForm from '@/lib/requestAccess/models/DashboardRequestAccessForm';
import FormFieldNames from '@/lib/requestAccess/models/formFieldNames';
import RequestAccessForm from '@/lib/requestAccess/models/RequestAccessForm';

const formUrl = 'https://app.smartsheet.com/b/form/182234f19fe84461954cf7f9f08c177a';
class DashboardIssueForm extends RequestAccessForm {
  static EXTRA_INFO_FIELD = 'Provide some brief description of the issue or request';

  constructor() {
    super(formUrl);
    this.type = 'Issue';
    this.title = 'Report an Issue';
  }

  set type(value) {
    this.formData[FormFieldNames.TYPE] = value;
  }

  set extraInfo(value) {
    this.formData[DashboardIssueForm.EXTRA_INFO_FIELD] = value;
  }
}
export default DashboardIssueForm;
