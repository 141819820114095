import axios from 'axios';

// Setting up Okta Auth.
import authProvider from '../../setup/auth-provider';
import common from '../../config/common';
// import AuthApi from '../../api/AuthApi';

function getHttpClient() {
  const httpClient = axios.create({
    baseURL: common.discoveryEndPoint(),
  });

  httpClient.interceptors.request.use(async (config) => {
    const token = await authProvider().bearerToken();
    // const token = await authProvider().getAccessToken();
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  }, (error) => Promise.reject(error));

  return httpClient;
}

const httpClient = getHttpClient();

export default {
  /* s */

  httpClient,

  /**
   * GetEntities for uploader page
   * @param commit
   * @returns {Promise<*>}
   */
  async getEntities(entityRelativeUrl) {
    const params = {};
    // const apiRelativeUrl = `${entityRelativeUrl}`;
    // const { data } = await this.httpClient.get(apiRelativeUrl, { params });
    let data = null;
    try {
      const response = await this.httpClient.get(entityRelativeUrl, { params });
      data = response.data;
    } catch (error) {
      console.error(error);
    }

    return data;
  },

  /**
   * saveAssetWithMetadata for uploader page
   * @param commit
   * @param postdata
   * @returns {Promise<*>}
   */
  async createNewMetadata(postdata) {
    // console.log('in services createNewMetadata');
    // console.log(postdata);
    let responsedata = null;
    try {
      const response = await this.httpClient.post('createNewMetadata', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },
  async saveAssetWithMetadata(postdata) {
    // console.log('in services saveAssetWithMetadata');
    // console.log(postdata);
    let responsedata = null;
    try {
      const response = await this.httpClient.post('saveAssetWithMetadata', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },
  async markAsUploaded(postdata) {
    let responsedata = null;
    try {
      const response = await this.httpClient.post('markAsUploaded', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },
  async markPreviewAsUploaded(postdata) {
    let responsedata = null;
    try {
      const response = await this.httpClient.post('markPreviewAsUploaded', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },

  async triggerVideoIndexing(postdata) {
    // console.log('in services saveAssetWithMetadata');
    // console.log(postdata);
    let responsedata = null;
    try {
      const response = await this.httpClient.post('triggerVideoIndexingUploader', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },

  async updateAssetWithMetadata(postdata) {
    // console.log('in services updateAssetWithMetadata');
    // console.log(postdata);
    let responsedata = null;
    try {
      const response = await this.httpClient.post('updateAssetWithMetadata', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },

  async updateAssetCollections(postdata) {
    // console.log('in services updateAssetWithMetadata');
    // console.log(postdata);
    let responsedata = null;
    try {
      const response = await this.httpClient.post('updateAssetCollections', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },

  // async updateAssetCollections(postdata) {
  //   // console.log('in services updateAssetCollections');
  //   // console.log(postdata);
  //   let responsedata = null;
  //   try {
  //     const response = await this.httpClient.post('updateAssetCollections', postdata);
  //     responsedata = response.data;
  //     // console.log(responsedata);
  //   } catch (error) {
  //     console.error(error);
  //   }
  //
  //   return responsedata;
  // },

  async saveAssetCollections(postdata) {
    // console.log('in services saveAssetCollections');
    // console.log(postdata);
    let responsedata = null;
    try {
      const response = await this.httpClient.post('saveAssetCollections', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },

  async createMetadataEdge(postdata) {
    // console.log('in services createEdge');
    // console.log(postdata);
    let responsedata = null;
    try {
      const response = await this.httpClient.post('createEdge', postdata);
      responsedata = response.data;
      // console.log(responsedata);
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },
  async initiateThumbnailingProcess(assetId) {
    let responsedata = null;
    try {
      const response = await this.httpClient.post(`processThumbnailsOnFileReplacement/${assetId}`);
      console.log('initiateThumbnailingProcess: Response:', responsedata);
      responsedata = response.data;
    } catch (error) {
      console.error(error);
    }

    return responsedata;
  },
};
