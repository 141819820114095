<template>
  <v-app :class="[breakpointClass, brandClass, adminClass, pathClass]">
    <template v-if="isPhoneInLandscapeMode && !onEmbedPage">
      <div class="landscape_warning d-flex justify-center flex-column align-center">
        <div><v-icon size="64" color="black" class="material-symbols-outlined">screen_rotation_up</v-icon></div>
        <div><strong>Please rotate your device.</strong></div>
      </div>

    </template>

    <busy-cursor></busy-cursor>
    <console></console>
    <snackbar-message ref="SnackbarMessage"></snackbar-message>
    <maintenance-alert v-model="showMaintenanceAlert" v-if="false"/>
    <my-sites-selector v-model="isSiteSelectorOpen"
      @index-changed="onIndexChanged"
    />
    <system-message-display
      :show.sync="hasSystemMessage"
      :system-message="systemMessage"
    />
    <discoveryPortalAppBar
      v-if="showHeader"
      :mobile-name="mobileName"
      @start-search="onSearch"
      @index-changed="onIndexChange"
      @logout="logout"
      @login="login">
      <template slot="middle"></template>
    </discoveryPortalAppBar>

    <v-main>
      <v-container fluid pa-0 pb-15 class="main-container">
        <router-view name="main" class="deployment-name" :deployment-name="deploymentName"></router-view>
      </v-container>
    </v-main>

    <right-side-router-view></right-side-router-view>
    <discovery-panel-drawer
      :factory="panelFactory"
      v-model="showPanel"
      :panel="currentOpenRightPanel">

    </discovery-panel-drawer>
    <discovery-dialog-container></discovery-dialog-container>
    <v-footer class="white">
      <v-spacer></v-spacer>
      0.0.14 <span v-if="showEnv" class="ml-3"> {{ env }}</span>
    </v-footer>

  </v-app>
</template>

<script>
import {
  mapState, mapActions, mapGetters, mapMutations,
} from 'vuex';
import common from '@/config/common';
import BusyCursor from '@/components/BusyCursor.vue';
import MaintenanceAlert from '@/components/MaintenanceAlert.vue';
import Console from '@/components/Console.vue';
import SnackbarMessage from '@/components/SnackbarMessage.vue';
import DiscoveryPanelDrawer from '@/components/ui/panels/DiscoveryPanelDrawer.vue';
import RightPanelFactory from '@/lib/ui/panels/RightPanelFactory';
import DiscoveryDialogContainer from '@/components/ui/dialogs/DiscoveryDialogContainer.vue';
import searchMixin from '@/components/search/mixins/searchMixin';
import MySitesSelector from '@/components/deployments/MySitesSelector.vue';
import sitesMixin from '@/views/mixins/sitesMixin';
import RightSideRouterView from './components/RightSideRouterView.vue';
import SystemMessageDisplay from './components/common/systemMessage/SystemMessageDisplay.vue';
import discoveryPortalAppBar from './components/discoveryPortalAppBar.vue';
import { ApiFactory, ApiNames } from './api/api-factory';

const authApi = ApiFactory.get(ApiNames.AUTH);

export default {
  name: 'App',

  mixins: [
    searchMixin,
    sitesMixin,
  ],

  components: {
    DiscoveryDialogContainer,
    DiscoveryPanelDrawer,
    BusyCursor,
    MaintenanceAlert,
    Console,
    SnackbarMessage,
    SystemMessageDisplay,
    discoveryPortalAppBar,
    RightSideRouterView,
    MySitesSelector,
  },

  computed: {
    ...mapState('common', [
      'activeUser',
      'appBusy',
      'appBusyMessage',
      'userDeployments',
      'showSharePanel',
      'showRightPanel',
      'rightPanelName',
      'rightPanelManager',
      'isMySitesSelectorOpen',
      'systemMessage',
    ]),
    ...mapGetters('common', [
      'isAuthenticated',
      'selectedDeploymentIndex',
      'isMaintenanceAlertAcknowledged',
    ]),
    ...mapState('discovery', [
      'searchParams',
    ]),
    ...mapGetters('discovery', [
      'facetList',
    ]),
    isPhoneInLandscapeMode() {
      const phoneInLandscapeMode = this.isPhone && this.orientation === 'landscape';

      return phoneInLandscapeMode;
    },
    onEmbedPage() {
      return this.$route.name === 'asset-embed';
    },
    env() {
      return common.env;
    },
    showEnv() {
      return this.env != null;
    },
    breakpointClass() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs': return 'mobile';
        case 'sm': return 'tablet';
        case 'md': return 'laptop';
        case 'lg': return 'desktop';
        case 'xl': return 'ultra';
        default: return 'desktop';
      }
    },
    deployment() {
      return this.userDeployments.currentDeployment;
    },
    deploymentName() {
      return (this.deployment?.name || 'default').toLowerCase();
    },
    brandClass() {
      const deploymentName = (this.deployment?.name || 'default').toLowerCase();
      return `index--${deploymentName}`;
    },
    adminClass() {
      return (this.$route.meta?.isAdmin ? 'admin' : '');
    },
    pathClass() {
      return (this.$route.name);
    },
    showPanel: {
      get() {
        return this.rightPanelManager.hasUnclosedPanels;
      },
      set(value) {
        this.rightPanelManager.close();
        this.setShowRightPanelState(value);
      },
    },
    currentOpenRightPanel() {
      return this.rightPanelManager.topPanel;
    },
    isInMaintenance() {
      return common.isInMaintenance;
    },
    showHeader() {
      if (this.isInMaintenance) {
        return false;
      }
      return this.isAuthenticated || !(this.$route.meta?.hideHeader);
    },
    isSiteSelectorOpen: {
      get() {
        return this.isMySitesSelectorOpen;
      },
      set(value) {
        this.setMySitesSelectorDialog(value);
      },
    },
    mobileName() {
      if (this.$route.meta.mobileName === 'sitename') {
        return this.selectedDeploymentIndex?.customizationSettings?.getSetting('mobileTitle')?.value || '';
      }
      return this.$route.meta.mobileName;
    },
    showMaintenanceAlert: {
      get() {
        return this.isMaintenanceAlertNeeded;
      },
      async set(value) {
        await this.setIsMaintenanceAlertAcknowledged(value);
        this.isMaintenanceAlertNeeded = false;
      },
    },
    hasSystemMessage: {
      get() {
        return this.systemMessage != null;
      },
      set(value) {
        if (!value) {
          this.setSystemMessage(null);
        }
      },
    },

  },
  data: () => ({
    //
    version: 'version-check=0.0.11',
    panelFactory: new RightPanelFactory(),
    isMaintenanceAlertNeeded: false,
    orientation: 'portrait',
    isPhone: false,
    showSystemMessage: true,
  }),
  async mounted() {
    const isMaintenanceAlertAcknowledged = await this.isMaintenanceAlertAcknowledged;
    this.isMaintenanceAlertNeeded = !isMaintenanceAlertAcknowledged;

    await this.refreshActiveUser();
    this.siteCheck();
    this.$root.SnackbarMessage = this.$refs.SnackbarMessage;

    this.checkOrientation();
    window.addEventListener('resize', this.checkOrientation);
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.checkOrientation);
  },
  methods: {
    ...mapActions('common', [
      'setActiveUser',
      'setShowRightPanelState',
      'getUserDeployments',
      'checkForDeployments',
      'checkForDeploymentIndex',
      'setUserUserSelectedIndex',
      'setIsMaintenanceAlertAcknowledged',
      'setSiteNotFoundError',
    ]),
    ...mapMutations('common', [
      'setSelectedDeployment',
      'openMySitesSelectorDialog',
      'setMySitesSelectorDialog',
      'setSystemMessage',
    ]),
    ...mapActions('discovery', [
      'search',
    ]),
    // will be incorporated into common
    ...mapActions('uploader', [
      'addDropFiles',
    ]),
    async login() {
      await authApi.login();
    },
    async refreshActiveUser() {
      const isAuthenticated = await this.$auth.isAuthenticated();

      if (isAuthenticated && this.activeUser == null) {
        const activeUser = await authApi.getUser();

        // setting username in localstorage for webchat
        window.localStorage.setItem('userName', activeUser.name);

        await this.setActiveUser(activeUser);
        const userDeployments = await this.getUserDeployments();
        console.log('inside refresh active user', userDeployments);
      }
    },
    async logout() {
      await authApi.logout();
      await this.refreshActiveUser();
      this.$router.push({ name: 'welcome' });
    },
    onSearch(queryString) {
      const isSemanticSearch = this.searchParams?.isSemanticSearch;
      const exactPhrase = this.searchParams?.isfullTextQuery;
      const query = {
        q: encodeURI(queryString),
        semantic: isSemanticSearch,
        exactPhrase,
      };

      console.log('Searching in route', this.$route.name);
      if (this.$router.currentRoute.name === 'indexSearch') {
        this.addFacetsToURL(query);
        this.search({
          searchString: queryString,
          facetList: this.facetList,
          orderBy: queryString ? '' : 'published_date desc',
        });
        return;
      }

      const newQuery = this.facetList.updateQuery(query);
      this.$router.push({ name: 'indexSearch', query: newQuery });
    },
    async onIndexChange(index) {
      this.searchParams.isSemanticSearch = false;
      if (this.$route.name === 'indexSearch') {
        await this.$router.push({
          params: { indexId: index.deploymentIndexId },
        });
      } else {
        await this.$router
          .push({
            name: 'indexSearch',
            params: { indexId: index.deploymentIndexId },
          });
      }

      this.onSearch('');
    },
    async onIndexChanged(index) {
      this.initialiseSiteChange(index);
    },
    closePanel() {
      this.showPanel = false;
    },
    async checkIndexValidity(userDeployments, indexId) {
      console.log('Checking validity of index', indexId);
      const currentDeployment = userDeployments.setUserSelectedDeployment(indexId);
      const index = currentDeployment?.currentIndex;
      if (index == null) {
        const pagePath = this.$route.fullPath;
        await this.setSiteNotFoundError({ pagePath, indexId });
        await this.$router.replace({ name: 'siteNotFound' });
        return;
      }
      await this.setSelectedDeployment(currentDeployment);
      await this.setUserUserSelectedIndex(index);
    },
    async setDefaultIndex(userDeployments) {
      const deployment = userDeployments.setCurrentDeployment(null);
      const index = deployment.currentIndex;
      await this.setSelectedDeployment(deployment);
      await this.setUserUserSelectedIndex(index);
    },
    siteCheck() {
      const indexId = parseInt(this.$route.params?.indexId, 10) || 0;

      this.checkForDeployments((userDeployments) => {
        if (indexId > 0) {
          this.checkIndexValidity(userDeployments, indexId);
        } else {
          this.setDefaultIndex(userDeployments);
        }
      });
    },
    checkOrientation() {
      const mediaQuery = window.matchMedia('(orientation: portrait)');
      this.orientation = mediaQuery.matches ? 'portrait' : 'landscape';
      // this.isPhone = window.innerWidth < 768;
      this.isPhone = window.screen.width < 768;
      console.log(`Device orientation: ${this.orientation}`);
      console.log(`Device orientation: treat as phone: ${this.isPhone}`);
    },
    closeSystemMessage() {
      this.showSystemMessage = false;
    },
  },
  watch: {
    $route(to, from) {
      this.rightPanelManager.emptyStack();
      console.log('Watching route');
      // this.siteCheck();
    },
  },
};
</script>
<style lang="scss">
.landscape_warning {
  position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 5000;
    background-color: white;
    text-align: center;
}
</style>
