export default {
  children: [
    {
      path: 'home',
      name: 'collections-home',
      component: () => import(
        /* webpackChunkName: "collections-home-page" */
        '../components/collections/pages/CollectionsHomePage.vue'
      ),
      meta: {
        collectionType: 'default',
        mobileName: 'collections',
      },
    },
    {
      path: 'my-collections',
      name: 'my-collections',
      component: () => import(
        /* webpackChunkName: "collections-home-page" */
        '../components/collections/pages/CollectionsSubPage.vue'
      ),
      meta: {
        collectionType: 'my',
        mobileName: 'collections',
        pageTitle: 'My Collections',
      },
    },
    {
      path: 'shared-collections',
      name: 'shared-collections',
      component: () => import(
        /* webpackChunkName: "collections-subpage-shared" */
        '../components/collections/pages/CollectionsSubPage.vue'
      ),
      meta: {
        collectionType: 'shared',
        mobileName: 'collections',
        pageTitle: 'Shared Collections',
      },
    },
    {
      path: 'all-collections',
      name: 'all-collections',
      component: () => import(
        /* webpackChunkName: "collections-subpage-all" */
        '../components/collections/pages/CollectionsSubPage.vue'
      ),
      meta: {
        collectionType: 'all-public',
        mobileName: 'collections',
        pageTitle: 'All Collections',
      },
    },
    {
      path: 'pinned-collections',
      name: 'pinned-collections',
      component: () => import(
        '../components/collections/pages/CollectionsSubPage.vue'
      ),
      meta: {
        collectionType: 'pinned',
        mobileName: 'collections',
        pageTitle: 'Collections Pinboard',
      },
    },
    {
      path: 'featured-collections',
      name: 'featured-collections',
      component: () => import(
        /* webpackChunkName: "collections-subpage-all" */
        '../components/collections/pages/CollectionsSubPage.vue'
      ),
      meta: {
        collectionType: 'featured',
        mobileName: 'collections',
        pageTitle: 'Featured Collections',
      },
    },
    {
      path: 'starred-collections',
      name: 'starred-collections',
      component: () => import(
        '../components/collections/pages/CollectionsSubPage.vue'
      ),
      meta: {
        collectionType: 'starred',
        mobileName: 'collections',
        pageTitle: 'Starred Collections',
      },
    },
  ],
};
