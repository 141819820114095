import RequestAccessForm from '@/lib/requestAccess/models/RequestAccessForm';
import formFieldNames from '@/lib/requestAccess/models/formFieldNames';

const formUrl = 'https://app.smartsheet.com/b/form/26b3d37029314c43a8a0c73316315cde';
class ReportAnIssueForm extends RequestAccessForm {
  constructor() {
    super(formUrl);
    this.title = 'Report An Issue';
  }
}

export default ReportAnIssueForm;
