export default {
  methods: {
    previewLink(location) {
      const { origin } = window.location;

      const { href } = this.$router.resolve(location);

      return `${origin}${href}`;
    },
  },
};
