/* eslint-disable-next-line no-bitwise */

const ExternalUrlActionTypes = {
  EMBED: 'embed',
  NEW_WINDOW: 'new window',
  POWER_BI_EMBED: 'power bi embed',
  POWER_BI_EMBED_WITH_ACTIONS_BAR: 'power bi embed w/actions bar',
  POWER_BI_EMBED_WITH_STARFISH_TOOLBAR: 'power bi embed w/starfish toolbar',
  POWER_BI_EMBED_WITH_ACTIONS_AND_STARFISH_TOOLBAR: 'power bi embed w/actions & starfish tool bar',
  API_POWER_BI_EMBED: 'api - power bi embed',
  API_POWER_BI_EMBED_WITH_ACTIONS: 'api - power bi embed w/actions',
  API_POWER_BI_EMBED_WITH_ACTIONS_AND_STARFISH_ADVANCE: 'api - power bi embed w/actions & starfish advance',
  API_POWER_BI_EMBED_WITH_ACTIONS_AND_STARFISH_BASIC: 'api - power bi embed w/actions & starfish basic',
  API_POWER_BI_EMBED_WITH_STARFISH_ADVANCE: 'api - power bi embed w/starfish advance',
  API_POWER_BI_EMBED_WITH_STARFISH_BASIC: 'api - power bi embed w/starfish basic',
  TABLEAU_EMBED: 'tableau embed',
  TABLEAU_EMBED_WITH_STARFISH_TOOLBAR: 'tableau embed w/starfish toolbar',
  TABLEAU_EMBED_WITH_ACTIONS_BAR: 'tableau embed w/actions bar',
  TABLEAU_EMBED_WITH_ACTIONS_AND_STARFISH_TOOLBAR: 'tableau embed w/actions & starfish toolbar',
  NONE: 'none',
};

export const Actions = {
  LIKE: 2,
  SHARE: 4,
  ADD_TO_COLLECTION: 8,
  FULLSCREEN: 16,
  DOWNLOAD: 32,
  DOWNLOAD_TABLEAU: 33,
  DOWNLOAD_POWERBI: 35,
  ANALYZE: 64,
  INTERACT: 128,
  RESET: 256,
  REFRESH: 512,
};

// eslint-disable-next-line no-bitwise
const BASIC_ACTIONS = Actions.LIKE | Actions.SHARE | Actions.ADD_TO_COLLECTION | Actions.FULLSCREEN;

// eslint-disable-next-line no-bitwise
const ADVANCED_ACTIONS = BASIC_ACTIONS | Actions.DOWNLOAD | Actions.INTERACT;

const STARFISH_ACTION = 1024;

const APPLICATION_ACTIONS = 2048;

// eslint-disable-next-line no-bitwise
const TABLEAU_APP_ACTIONS = APPLICATION_ACTIONS | Actions.DOWNLOAD_TABLEAU | Actions.REFRESH | Actions.RESET;

// eslint-disable-next-line no-bitwise
const POWERBI_API_APP_ACTIONS = APPLICATION_ACTIONS | Actions.DOWNLOAD_POWERBI;

export const ActionTypes = {
  // eslint-disable-next-line no-bitwise
  STARFISH_BASIC: STARFISH_ACTION | BASIC_ACTIONS,

  // eslint-disable-next-line no-bitwise
  STARFISH_ADVANCED: STARFISH_ACTION | BASIC_ACTIONS | ADVANCED_ACTIONS,

  APPLICATION_ACTIONS: 2048,

  TABLEAU_APP_ACTIONS,

  POWERBI_API_APP_ACTIONS,
};

export const ViewTypes = {
  NONE: 0,
  POWERBI: 4096,
  TABLEAU: 8192,
  EMBED: 16384,
  NEW_WINDOW: 32768,
};

export const PowerBIEmbedOptions = {
  NONE: -1,
  IFRAME: 0,
  COMPONENT: 1,
};

// eslint-disable-next-line no-bitwise

export const EmbedOptions = {
  none: 0,

  embed: ViewTypes.EMBED,
  'new window': ViewTypes.NEW_WINDOW,

  'power bi embed': ViewTypes.POWERBI, // 1000

  // eslint-disable-next-line no-bitwise
  'power bi embed w/actions': (ViewTypes.POWERBI | ActionTypes.APPLICATION_ACTIONS),

  // eslint-disable-next-line no-bitwise
  'power bi embed w/actions & starfish advance': (ViewTypes.POWERBI | ActionTypes.APPLICATION_ACTIONS | ActionTypes.STARFISH_ADVANCED),

  // eslint-disable-next-line no-bitwise
  'power bi embed w/actions & starfish basic': (ViewTypes.POWERBI | ActionTypes.APPLICATION_ACTIONS | ActionTypes.STARFISH_BASIC),

  // eslint-disable-next-line no-bitwise
  'power bi embed w/starfish advance': (ViewTypes.POWERBI | ActionTypes.STARFISH_ADVANCED),

  // eslint-disable-next-line no-bitwise
  'power bi embed w/starfish basic': (ViewTypes.POWERBI | ActionTypes.STARFISH_BASIC),

  'api - power bi embed': ViewTypes.POWERBI, // 1000

  // eslint-disable-next-line no-bitwise
  'api - power bi embed w/actions': (ViewTypes.POWERBI | ActionTypes.POWERBI_API_APP_ACTIONS),

  // eslint-disable-next-line no-bitwise
  'api - power bi embed w/actions & starfish advance': (ViewTypes.POWERBI | ActionTypes.POWERBI_API_APP_ACTIONS | ActionTypes.STARFISH_ADVANCED),

  // eslint-disable-next-line no-bitwise
  'api - power bi embed w/actions & starfish basic': (ViewTypes.POWERBI | ActionTypes.POWERBI_API_APP_ACTIONS | ActionTypes.STARFISH_BASIC),

  // eslint-disable-next-line no-bitwise
  'api - power bi embed w/starfish advance': (ViewTypes.POWERBI | ActionTypes.STARFISH_ADVANCED),

  // eslint-disable-next-line no-bitwise
  'api - power bi embed w/starfish basic': (ViewTypes.POWERBI | ActionTypes.STARFISH_BASIC),

  // eslint-disable-next-line no-bitwise
  'tableau embed': (ViewTypes.TABLEAU),

  // eslint-disable-next-line no-bitwise
  'tableau embed w/actions': (ViewTypes.TABLEAU | ActionTypes.TABLEAU_APP_ACTIONS),

  // eslint-disable-next-line no-bitwise
  'tableau embed w/actions & starfish advance': (ViewTypes.TABLEAU | ActionTypes.TABLEAU_APP_ACTIONS | ActionTypes.STARFISH_ADVANCED),

  // eslint-disable-next-line no-bitwise
  'tableau embed w/actions & starfish basic': (ViewTypes.TABLEAU | ActionTypes.TABLEAU_APP_ACTIONS | ActionTypes.STARFISH_BASIC),

  // eslint-disable-next-line no-bitwise
  'tableau embed w/starfish advance': (ViewTypes.TABLEAU | ActionTypes.STARFISH_ADVANCED),

  // eslint-disable-next-line no-bitwise
  'tableau embed w/starfish basic': (ViewTypes.TABLEAU | ActionTypes.STARFISH_BASIC),
};

// eslint-disable-next-line no-bitwise
const isTableau = (type) => (EmbedOptions[type] & ViewTypes.TABLEAU) === ViewTypes.TABLEAU;

// eslint-disable-next-line no-bitwise
const isPowerBi = (type) => (EmbedOptions[type] & ViewTypes.POWERBI) === ViewTypes.POWERBI;

// eslint-disable-next-line no-bitwise
const hasTableauActions = (type) => (EmbedOptions[type] & ActionTypes.APPLICATION_ACTIONS) === ActionTypes.APPLICATION_ACTIONS;

// eslint-disable-next-line no-bitwise
const hasStarfishActions = (type) => (EmbedOptions[type] & ActionTypes.STARFISH_BASIC) === ActionTypes.STARFISH_BASIC;

// eslint-disable-next-line no-bitwise
const hasAdvancedStarfishActions = (type) => (EmbedOptions[type] & ActionTypes.STARFISH_ADVANCED) === ActionTypes.STARFISH_ADVANCED;

/**
 * Checks if the embed action contains the action or action type or view type mentioned by the type.
 *
 * @param embedAction{string}
 * @param type{number}
 * @returns {boolean}
 */
// eslint-disable-next-line no-bitwise
const has = (embedAction, type) => (EmbedOptions[embedAction] & type) === type;

const powerBiEmbedType = (embedAction) => {
  if (isPowerBi(embedAction)) {
    return embedAction.toLowerCase().startsWith('api') ? PowerBIEmbedOptions.COMPONENT : PowerBIEmbedOptions.IFRAME;
  }

  return PowerBIEmbedOptions.NONE;
};

/**
 *
 * Checks if the embed action refers to embed power bi dashboard as iframe
 * @param action
 * @returns {boolean}
 */
const embedPowerBiIFrame = (action) => powerBiEmbedType(action) === PowerBIEmbedOptions.IFRAME;

/**
 * Checks if the embed action refers to embed power bi dashboard using powerbi-client
 * @param action
 * @returns {boolean}
 */
const embedPowerBiComponent = (action) => powerBiEmbedType(action) === PowerBIEmbedOptions.COMPONENT;

const isOpenInNewWindow = (action) => has(action, ViewTypes.NEW_WINDOW);

export const ExternalEmbedSystemsChecker = {
  isPowerBi,
  isTableau,
  hasExternalSystemActions: hasTableauActions,
  hasStarfishActions,
  hasAdvancedStarfishActions,
  has,
  embedPowerBiIFrame,
  embedPowerBiComponent,
  isOpenInNewWindow,
};

export default ExternalUrlActionTypes;
