import {
  mapState,
} from 'vuex';
import previewLinkMixin from '@/components/mixins/previewLinkMixin';

export default {
  mixins: [
    previewLinkMixin,
  ],
  computed: {
    ...mapState('discovery', [
      'currentDocument',
    ]),
    isThisEmbedPage() {
      return this.$route.name === 'asset-embed';
    },
    docId() {
      return this.$route.query?.docId || this.currentDocument?.id;
    },
    title() {
      return this.currentDocument?.title;
    },
    contentPageRoute() {
      if (this.docId == null) {
        return { name: 'indexHome' };
      }

      return { name: 'content-page', params: { id: this.docId } };
    },
    dashboardCategory() {
      return this.currentDocument?.dashboardCategory;
    },
    keyContactsList() {
      const keyContacts = this.currentDocument?.keyContacts.map((kc) => kc.email);
      return (keyContacts || []).join(', ');
    },
    assetInfo() {
      return this.getAssetInfo();
    },
    dashboardInfo() {
      const assetInfo = this.getAssetInfo(null, 'Dashboard');
      const contacts = `Key Contact: ${this.keyContactsList}\n`;
      const category = `Dashboard Category: ${this.dashboardCategory}\n`;
      return `${assetInfo}${category}\n${contacts}`;
    },
  },
  methods: {
    getAssetInfo(assetUrl, assetNameLabel = 'Content') {
      const assetMessage = `${assetNameLabel} Title: ${this.title}\n`;
      const url = `${assetNameLabel} URL: ${assetUrl}\n`;

      return `${assetMessage}${url}`;
    },
    getDashboardInfo(assetUrl) {
      const assetInfo = this.getAssetInfo(assetUrl, 'Dashboard');
      const contacts = `Key Contact: ${this.keyContactsList}\n`;
      console.log('getDashboardInfo key contacts', contacts);
      const category = `Dashboard Category: ${this.dashboardCategory}\n`;
      return `${assetInfo}${category}\n${contacts}`;
    },
  },
};
