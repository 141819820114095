import AppProperty from '@/lib/customizations/AppProperty';

class AppObjectSettingValue {
    appObjectSettingValueId = 0;

    appObjectSettingId = 0;

    appPropertyId = 0;

    value = '';

    appPropertyCode = '';

    appPropertyName = '';

    appPropertyType = '';

    propertyGroupName = '';

    characterCount = '';

    userId = 0;

    allowSameValueUsage = false;

    reusedByAppPropertyCode = '';

    useAsOtherImagery = false;

    isDisabled = false;

  /**
   *
   * @type {AppProperty|null}
   */
  appProperty = null;

  constructor(
    appObjectSettingValueId = 0,
    appObjectSettingId = 0,
    appPropertyId = 0,
    value = '',
    appPropertyCode = '',
    appPropertyName = '',
    appPropertyType = '',
    propertyGroupName = '',
    userId = 0,
    appProperty = null,
    characterCount = 0,
    allowSameValueUsage = false,
    reusedByAppPropertyCode = '',
  ) {
    this.appObjectSettingValueId = appObjectSettingValueId;
    this.appObjectSettingId = appObjectSettingId;
    this.appPropertyId = appPropertyId;
    this.value = value;
    this.appPropertyCode = appPropertyCode;
    this.appPropertyName = appPropertyName;
    this.appPropertyType = appPropertyType;
    this.propertyGroupName = propertyGroupName;
    this.userId = userId;
    this.appProperty = AppProperty.fromObject(appProperty);
    this.characterCount = characterCount;
    this.allowSameValueUsage = allowSameValueUsage;
    this.reusedByAppPropertyCode = reusedByAppPropertyCode;
  }

  static fromObject(data) {
    if (data == null) {
      return null;
    }

    const {
      appObjectSettingValueId,
      appObjectSettingId,
      appPropertyId,
      value,
      appPropertyCode,
      appPropertyName,
      appPropertyType,
      propertyGroupName,
      userId,
      appProperty,
      characterCount,
      allowSameValueUsage,
      reusedByAppPropertyCode,
    } = data; // need to check this once
    return new AppObjectSettingValue(
      appObjectSettingValueId,
      appObjectSettingId,
      appPropertyId,
      value,
      appPropertyCode,
      appPropertyName,
      appPropertyType,
      propertyGroupName,
      userId,
      appProperty,
      characterCount,
      allowSameValueUsage,
      reusedByAppPropertyCode,
    );
  }

  static fromObjects(list) {
    if (list == null) {
      return null;
    }
    return list.map((data) => AppObjectSettingValue.fromObject(data));
  }
}
export default AppObjectSettingValue;
